import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RadioConditionsPipe } from './radio-conditions.pipe';
import { RadioConditionKeyPipe } from './radio-condition-key.pipe';
import { SymptomModePipe } from './symptom-mode.pipe';
import { SymptomsPipe } from './symptoms.pipe';
import { SymptomMethodsPipe } from './symptom-methods.pipe';
import { SymptomValuePipe } from './symptom-value.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    RadioConditionsPipe,
    RadioConditionKeyPipe,
    SymptomModePipe,
    SymptomsPipe,
    SymptomMethodsPipe,
    SymptomValuePipe
  ],
  exports: [
    RadioConditionsPipe,
    RadioConditionKeyPipe,
    SymptomModePipe,
    SymptomMethodsPipe,
    SymptomsPipe,
    SymptomValuePipe
  ]
})
export class SymptomsPipesModule {}
