import { Component, Input, OnInit } from '@angular/core';
import { FormTemplateGroup } from '../../form-template-model';
import { FormTemplateGroupFormGroup } from './../../form-template-form-group';
import { formTemplateGenericSort } from './../../form-template-model';

@Component({
  selector: 'csi-form-template-group-generator',
  templateUrl: './form-template-group-generator.component.html',
  styleUrls: ['./form-template-group-generator.component.css']
})
export class FormTemplateGroupGeneratorComponent implements OnInit {
  @Input() formTemplateGroup: FormTemplateGroup;
  @Input() formTemplateGroupFormGroup: FormTemplateGroupFormGroup;

  sortFields = formTemplateGenericSort;
  constructor() {}

  ngOnInit() {}
}
