import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { LocationSelectService } from '../location-select.service';
import { Locations } from '../location.model';

@Component({
  selector: 'csi-region-select',
  templateUrl: './region-select.component.html',
  styleUrls: ['./region-select.component.css']
})
export class RegionSelectComponent {
  @Input() regionControl: FormControl;
  @Input() set country(country: string) {
    this.regions$ = this.locationSelectService.getRegionsForCountry(country);
    this._country = country;
  }

  get country() {
    return this._country;
  }

  private _country: string;
  public regions$: Observable<Locations>;

  constructor(private locationSelectService: LocationSelectService) {}
}
