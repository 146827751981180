import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { ApolloError } from 'apollo-client';
import {
  CreateIntakeFormInput,
  IntakeFormStatus,
  QuestionnaireRequest,
  QuestionnaireRequestResponse,
  QuestionnaireType
} from 'src/API';
import { PatientFormGroup } from 'src/app/shared/consult-forms/patient-form/patient-form.model';
import { PatientService } from '../core/api/patient.service';
import { OSDIService } from '../questionnaires/osdi/osdi.service';
import { QuestionnaireRoutePath } from '../questionnaires/questionnaires-routing.module';
import { ConsentFormComponent } from '../questionnaires/simple-questionnaire/consent-form/consent-form.component';
import { SPEEDService } from '../questionnaires/speed/speed.service';
import { generalButtonMap, IntakeFormGroupType, intakeFormSchema } from './intake-form-schema';
import { IntakeFormService } from './intake-form.service';

@Component({
  selector: 'csi-intake-form',
  templateUrl: './intake-form.component.html',
  styleUrls: ['./intake-form.component.css'],
  providers: [SPEEDService, OSDIService]
})
export class IntakeFormComponent implements OnInit {
  @ViewChild('stepper', { static: false }) private stepper: MatStepper;

  public submitFormControl = new FormControl();
  private clinicID: string;
  public country: string;

  public intakeFormSchema = intakeFormSchema;
  public intakeFormGroup = new FormGroup({});
  public IntakeFormGroupType = IntakeFormGroupType;

  public groupChanges;
  public buttonMaps = generalButtonMap;
  private activatedRoute: ActivatedRoute;

  constructor(
    private intakeFormService: IntakeFormService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    public speedService: SPEEDService,
    public osdiService: OSDIService,
    private router: Router,
    private patientService: PatientService,
    private translocoService: TranslocoService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.clinicID = this.route.snapshot.params.id;
    this.country = this.route.snapshot.queryParams.country || 'Canada';

    Object.keys(this.intakeFormSchema).forEach(key => {
      let group = this.intakeFormSchema[key];
      if (group.type === IntakeFormGroupType.PatientCard) {
        let pGroup = new PatientFormGroup();
        pGroup.setValidators(this.patientService.contactValidator);
        this.intakeFormGroup.addControl(key, pGroup);
      } else {
        this.intakeFormGroup.addControl(key, this.generateFormGroup(group.config));
      }
    });
  }

  generateFormGroup(questionList: any) {
    let formGroup = new FormGroup({});
    Object.keys(questionList).forEach(question => {
      const validators = questionList[question].validators || null;
      const questions = questionList[question];
      if (questions.inputType.type === 'checkbox') {
        formGroup.addControl(question, new FormControl(false, validators));
      } else if (questions.inputType.type === 'checkboxGroup') {
        formGroup.addControl(
          question,
          new FormGroup(
            Object.keys(questions.inputType.checkboxes).reduce(
              (group, key) => ({ ...group, [key]: new FormControl() }),
              {}
            ),
            validators
          )
        );
      } else {
        formGroup.addControl(question, new FormControl(null, validators));
      }
    });
    formGroup.valueChanges.subscribe(() => {
      setTimeout(() => {
        this.groupChanges = new Date();
        setTimeout(() => {
          setTimeout(() => {
            setTimeout(() => {
              this.changeDetectorRef.markForCheck();
            });
          });
        });
      });
    });
    return formGroup;
  }

  noSort() {
    return 0;
  }

  submit() {
    if (this.submitFormControl.valid || true) {
      const questionnaire: Partial<QuestionnaireRequest> = {
        response: {
          answers: JSON.stringify({
            [QuestionnaireType.SPEED]: this.speedService.formGroup.value,
            [QuestionnaireType.OSDI]: this.osdiService.formGroup.value
          }),
          scores: JSON.stringify({
            [QuestionnaireType.SPEED]: this.speedService.score,
            [QuestionnaireType.OSDI]: this.osdiService.score
          }),
          __typename: 'QuestionnaireRequestResponse'
        },
        type: QuestionnaireType.OSDIAndSPEED
      };
      const formResponse = { ...this.intakeFormGroup.value, questionnaire };

      const input: CreateIntakeFormInput = {
        status: IntakeFormStatus.none,
        response: JSON.stringify(formResponse),
        intakeFormClinicId: this.clinicID
      };
      this.intakeFormService.createIntakeFromInput(input).subscribe(
        () => this.redirectToSuccessPage(),
        (error: ApolloError) => {
          console.log(error);
          window.alert('We have encountered an error, please contact support@csidryeye.com.');
        }
      );
    }
  }

  openPrivacyConsentForm(event) {
    event.preventDefault();
    this.dialog.open(ConsentFormComponent, {
      data: { country: this.country }
    });
  }

  private redirectToSuccessPage(response?: QuestionnaireRequestResponse) {
    this.router.navigate([`questionnaires/${QuestionnaireRoutePath.ThankYou}`], {
      relativeTo: this.activatedRoute,
      queryParams: {
        isPositive: false,
        hideVideo: true,
        messageToShow: 'thankYouForSubmittingIntake',
        lang: this.translocoService.getActiveLang()
      }
    });
  }

  next(formGroup: FormGroup) {
    setTimeout(() => {
      formGroup.markAllAsTouched();
      formGroup.updateValueAndValidity();
      if (formGroup.valid) {
        this.stepper.next();
      }
    });
  }
}
