import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { leftEyeKey, rightEyeKey, symptomMethodKey } from '../../form.model';
import { KeyValueMap } from '../services/schema/schema.model';
import { SchemaService } from '../services/schema/schema.service';
import { Symptom, SymptomMethod, SymptomModes, SymptomTypes } from '../symptom.model';
import { DrawSymptomService } from './../draw-symptom-control/draw-symptom.service';

export interface SymptomRightLeftValues {
  key: string;
  rightEye?: SymptomValue;
  leftEye?: SymptomValue;
  type: SymptomTypes;
}

export interface SymptomValue {
  value: string | number | Observable<string>;
  method?: SymptomMethod;
  units?: string;
}

@Pipe({
  name: 'symptoms'
})
export class SymptomsPipe implements PipeTransform {
  constructor(
    private schemaService: SchemaService,
    private drawSymptomService: DrawSymptomService
  ) {}

  transform(formKeyValue: KeyValueMap, mode?: SymptomModes): SymptomRightLeftValues[] {
    const result: SymptomRightLeftValues[] = [];
    this.schemaService.symptomKeys.forEach(key => {
      const symptom: Symptom = this.schemaService.symptomMap[key];
      const type = symptom.type;
      if (!mode || symptom.modes.includes(mode)) {
        const rightEyeFormKey = rightEyeKey(key);
        const leftEyeFormKey = leftEyeKey(key);
        const rightMethodFormKey = symptomMethodKey(rightEyeFormKey);
        const leftMethodFormKey = symptomMethodKey(leftEyeFormKey);
        const rightValue = formKeyValue[rightEyeFormKey];
        const leftValue = formKeyValue[leftEyeFormKey];
        const rightMethod = formKeyValue[rightMethodFormKey] as SymptomMethod;
        const leftMethod = formKeyValue[leftMethodFormKey] as SymptomMethod;
        const rightUnits = this.schemaService.getUnitsForSymptomKey(rightEyeKey(key), rightMethod);
        const leftUnits = this.schemaService.getUnitsForSymptomKey(leftEyeKey(key), leftMethod);

        if (rightValue || leftValue) {
          result.push(
            this.createSymptomRightLeftValues(
              key,
              rightValue,
              leftValue,
              rightMethod,
              leftMethod,
              rightUnits,
              leftUnits,
              type
            )
          );
        }
      }
    });
    return result;
  }

  private createSymptomRightLeftValues(
    key: string,
    rightEyeValue: string | number,
    leftEyeValue: string | number,
    rightEyeMethod: SymptomMethod,
    leftEyeMethod: SymptomMethod,
    rightEyeUnits: string,
    leftEyeUnits: string,
    type: SymptomTypes
  ): SymptomRightLeftValues {
    const value: SymptomRightLeftValues = { key, type };

    if (!isNullOrUndefined(rightEyeValue)) {
      value.rightEye = {
        value:
          type !== SymptomTypes.Draw
            ? rightEyeValue
            : this.drawSymptomService.getImageUrlFromJSON(rightEyeValue),
        method: rightEyeMethod,
        units: rightEyeUnits
      };
    }
    if (!isNullOrUndefined(leftEyeValue)) {
      value.leftEye = {
        value:
          type !== SymptomTypes.Draw
            ? leftEyeValue
            : this.drawSymptomService.getImageUrlFromJSON(leftEyeValue),
        method: leftEyeMethod,
        units: leftEyeUnits
      };
    }

    return value;
  }
}
