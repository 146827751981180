import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit
} from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { Logger } from 'aws-amplify';
import { Observable, of } from 'rxjs';
import { delay, map, switchMap, tap } from 'rxjs/operators';
import { Clinic, Patient, QuestionnaireType } from 'src/API';
import { S3AttachmentService, S3Object } from 'src/app/shared/attachments/s3-attachment.service';
import { defaultLogoUri } from 'src/app/shared/dry-eye-pdf-export/default-logo-uri.model';
import { NameFormat } from 'src/app/shared/shared-pipes/person-name.pipe';
import { intakeFormSchema } from '../intake-form-schema';
import { IntakeFormService } from '../intake-form.service';
import { IntakeFormGroupType } from './../intake-form-schema';

const componentStyle = require('./intake-form-pdf-export.component.css');
// const intakeFormCategoryComponentStyle = require('./int')
@Component({
  selector: 'csi-intake-form-pdf-export',
  templateUrl: './intake-form-pdf-export.component.html',
  styleUrls: ['./intake-form-pdf-export.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntakeFormPdfExportComponent implements OnInit {
  private _clinicLogo: S3Object;
  public patient: Patient;
  private _data: any;
  public questionnaireType: QuestionnaireType;
  private logger: Logger = new Logger('IntakeFormPdfExportComponent');
  public readonly NameFormat = NameFormat;
  public defaultLogo = defaultLogoUri;
  clinicLogoDataUri: SafeUrl;

  public intakeFormSchema = intakeFormSchema;
  public IntakeFormGroupType = IntakeFormGroupType;

  @Input('data')
  set data(value) {
    this.patient = value.patientInformation;
    this._data = value;
  }
  get data() {
    return this._data;
  }
  @Input('clinicLogo')
  set clinicLogo(value: S3Object) {
    if (this.clinicLogo !== value) {
      this._clinicLogo = value;
      this.renderClinicLogo().subscribe();
    }
  }

  get clinicLogo() {
    return this._clinicLogo;
  }

  @Input() clinic: Clinic;
  @Input() hideDataForFaxing = false;

  constructor(
    private s3AttachmentService: S3AttachmentService,
    private hostElementRef: ElementRef,
    private changeDetector: ChangeDetectorRef,
    public intakeFormService: IntakeFormService
  ) {}

  ngOnInit() {
    this.intakeFormService.transformDeprecatedResponseKeys(this._data);
  }

  hasValue(responseData: { [key: string]: any }) {
    if (!responseData) {
      return false;
    }
    return Object.values(responseData).some(value => {
      if (typeof value === 'object') {
        return this.hasValue(value);
      }
      return value;
    });
  }

  get html(): Observable<string> {
    this.logger.debug('rendering pdf html');
    return this.render().pipe(
      tap(() => this.changeDetector.detectChanges()),
      delay(0),
      map(() => {
        this.changeDetector.detectChanges();
        this.logger.debug('calling innerHTMLWithStyle');
        const html = this.innerHTMLWithStyle;
        return html;
      })
    );
  }

  private render(): Observable<boolean> {
    this.logger.debug('render() called');
    return this.clinicLogoDataUri ? of(true) : this.renderClinicLogo();
  }
  private renderClinicLogo(): Observable<boolean> {
    this.logger.debug('rendering clinic logo');
    return of(this.clinicLogo).pipe(
      switchMap(logo => (logo ? this.s3AttachmentService.s3ObjectToUri(logo) : of(null))),
      map(logoDataUri => {
        this.logger.debug('setting logo data uri');
        this.clinicLogoDataUri = logoDataUri;
        return true;
      })
    );
  }

  private get innerHTMLWithStyle(): string {
    this.logger.debug('setting inner html');
    return this.hostElementRef.nativeElement.innerHTML.replace(
      '</head>',
      `<style>${componentStyle.default}</style></head>`
    );
  }
  noSort() {
    return 0;
  }
}
