import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { MatCheckboxChange, MatDialog, Sort } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { QuestionnaireRequest, QuestionnaireType } from 'src/API';
import { CopyToEmrModalComponent } from 'src/app/copy-to-emr-modal/copy-to-emr-modal.component';
import { Assessment } from 'src/app/core/api/assessment.service';
import { Patient, PatientService } from 'src/app/core/api/patient.service';
import { EncountersToCompare } from '../../encounters-compare-modal/encounters-compare-modal.component';
import { EncountersRoutes } from '../../encounters.routes';
import { QuestionnaireResponseModalComponent } from './../../../shared/consult-forms/questionnaire-response-modal/questionnaire-response-modal.component';

@Component({
  selector: 'csi-assessments-table',
  templateUrl: './assessments-table.component.html',
  styleUrls: ['./assessments-table.component.scss'],
  animations: [
    trigger('summaryExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class AssessmentsTableComponent implements OnInit {
  @Input() encountersToCompare: EncountersToCompare = [];

  public readonly encounterHeaderMap = {
    createdAt: 'Created At',
    updatedAt: 'Last Updated',
    assessmentMethod: 'Type',
    dryEyeCategory: 'Dry Eye Category'
  };

  public readonly encounterMethodFriendlyName = {
    advanced: 'Gold Standard',
    preOpScreening: 'Pre-Operative Screening',
    simple: 'Screening',
    preOp: 'ASCRS Pre-Op',
    treatment: 'Treatment',
    followUp: 'Follow Up'
  };

  public severityMap: { [key: string]: number } = {
    severe: 3,
    moderate: 2,
    mild: 1
  };

  public readonly QuestionnaireType = QuestionnaireType;

  public readonly dynamicEncounterHeaderKeys = Object.keys(this.encounterHeaderMap);
  public readonly allEncounterHeaderKeys = [
    'select',
    ...this.dynamicEncounterHeaderKeys,
    'actions'
  ];

  public EncountersRoutes = EncountersRoutes;

  public patient: Patient;

  public assessmentIdToExpand: string;

  public assessmentRowHoveredId: string;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private dialog: MatDialog,
    public patientService: PatientService
  ) {}
  ngOnInit() {}

  showQuestionnaireResponses(questionnaires: QuestionnaireRequest[]) {
    QuestionnaireResponseModalComponent.open(this.dialog, null, questionnaires);
  }

  announceSortChange(sort: Sort) {
    this.patientService.assessments = [
      ...this.patientService.assessments.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        const assessmentABody = JSON.parse(a.body);
        const assessmentBBody = JSON.parse(b.body);
        switch (sort.active) {
          case 'createdAt':
            return this.compareNumber(
              new Date(a.createdAt).getTime(),
              new Date(b.createdAt).getTime(),
              isAsc
            );
          case 'updatedAt':
            return this.compareNumber(
              new Date(a.updatedAt).getTime(),
              new Date(b.updatedAt).getTime(),
              isAsc
            );
          case 'assessmentMethod':
            const notApplicableCharacterValue = isAsc ? 'ZZZZZ' : 'aaaaa';

            let assessmentTypeA =
              assessmentABody.dryEyeForm && assessmentABody.dryEyeForm.assessmentMethod
                ? assessmentABody.dryEyeForm.assessmentMethod
                : notApplicableCharacterValue;

            let assessmentTypeB =
              assessmentBBody.dryEyeForm && assessmentBBody.dryEyeForm.assessmentMethod
                ? assessmentBBody.dryEyeForm.assessmentMethod
                : notApplicableCharacterValue;

            const friendlyNameA =
              this.encounterMethodFriendlyName[assessmentTypeA] || notApplicableCharacterValue;
            const friendlyNameB =
              this.encounterMethodFriendlyName[assessmentTypeB] || notApplicableCharacterValue;

            return this.compareString(friendlyNameA, friendlyNameB, isAsc);
          case 'dryEyeCategory':
            const categoryA =
              assessmentABody.assessmentForm && assessmentABody.assessmentForm.category;
            const categoryB =
              assessmentBBody.assessmentForm && assessmentBBody.assessmentForm.category;

            const severityA = (categoryA && categoryA.severity) || categoryA;

            const severityB = (categoryB && categoryB.severity) || categoryB;

            const scoreA = severityA ? this.severityMap[severityA.toLowerCase()] : '';

            const scoreB = severityB ? this.severityMap[severityB.toLowerCase()] : '';

            const notApplicableValue = isAsc ? 1000 : -1;

            return this.compareNumber(
              scoreA || notApplicableValue,
              scoreB || notApplicableValue,
              isAsc
            );
        }
      })
    ];
  }

  compareNumber(a: number, b: number, isAsc: boolean) {
    return (a - b) * (isAsc ? 1 : -1);
  }

  compareString(a: string, b: string, isAsc: boolean) {
    return a.localeCompare(b) * (isAsc ? 1 : -1);
  }

  encounterSelectionOnChange(matCheckboxChange: MatCheckboxChange, encounter: Assessment) {
    if (matCheckboxChange.checked) {
      this.encountersToCompare.push(encounter);
      return;
    }

    this.encountersToCompare.splice(
      this.encountersToCompare.findIndex(
        comparingEncounter => encounter.id === comparingEncounter.id
      ),
      1
    );
  }
  openCopyModal(assessment: Assessment, assessmentMethod) {
    this.dialog.open(CopyToEmrModalComponent, {
      data: {
        showAssessmentList: false,
        assessment: assessment,
        assessmentMethod: assessmentMethod
      },
      width: '95vw',
      minWidth: '50rem',
      height: '92vh'
    });
  }

  goToMobileMediaWizard(id) {
    this.router.navigateByUrl(`/${id}/mobile-media-wizard`);
  }
}
