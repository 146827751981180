import { AbstractControl, FormGroup } from '@angular/forms';
import { CountryCode, parsePhoneNumberFromString, PhoneNumber } from 'libphonenumber-js';

export function phoneNumberValidator(control: AbstractControl): { [key: string]: any } | null {
  if (!control.value || control.value.trim() === '') {
    return null;
  }
  const countries: CountryCode[] = ['CA', 'GB', 'US'];
  const phoneNumberString: string = control.value;
  const isPhoneNumberValid = countries.some(country => {
    const phoneNumber: PhoneNumber = parsePhoneNumberFromString(phoneNumberString, country);
    return phoneNumber && phoneNumber.isValid();
  });
  const invalidPhoneNumberReturn = { invalidPhoneNumber: { value: 'Invalid phone number. Please try adding a country code' } };
  return isPhoneNumberValid ? null : invalidPhoneNumberReturn;
}

export function phoneNumberAndCountryCodeValidator(
  phoneControlName: string,
  countryCodeControlName: string
) {
  return (group: FormGroup) => {
    const phoneControl = group.controls[phoneControlName];
    const countryCodeControl = group.controls[countryCodeControlName];
    const phoneNumberWithCountryCode: PhoneNumber = parsePhoneNumberFromString(
      countryCodeControl.value + phoneControl.value
    );
    if (
      (!phoneNumberWithCountryCode || !phoneNumberWithCountryCode.isValid()) &&
      phoneControl.value &&
      countryCodeControl.value
    ) {
      countryCodeControl.setErrors({
        invalidPhoneNumberOrCountryCode: true
      });
      phoneControl.setErrors({
        invalidPhoneNumberOrCountryCode: true
      });
      countryCodeControl.markAsTouched();
      phoneControl.markAsTouched();
    } else {
      deleteProperty(phoneControl, 'invalidPhoneNumberOrCountryCode');
      deleteProperty(countryCodeControl, 'invalidPhoneNumberOrCountryCode');
    }
    return null;
  };
}

function deleteProperty(control: AbstractControl, property) {
  if (control.errors) {
    delete control.errors[property];
    if (!Object.keys(control.errors).length) {
      control.setErrors(null);
    }
  }
}
