import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  CheckboxConfig,
  FormFieldConfig,
  formTemplateGenericSort
} from './../../../form-template-model';
import { FormTemplateGeneratorService } from './../../form-template-generator.service';

@Component({
  selector: 'csi-form-template-checkbox-field-generator',
  templateUrl: './form-template-checkbox-field-generator.component.html',
  styleUrls: ['./form-template-checkbox-field-generator.component.css']
})
export class FormTemplateCheckboxFieldGeneratorComponent implements OnInit {
  @Input() config: FormFieldConfig & { typeConfig: CheckboxConfig };
  @Input() checkboxFormGroup: FormGroup;
  @Input() formTemplateFieldKey: string;
  sortValues = formTemplateGenericSort;
  constructor(public formTemplateGeneratorService: FormTemplateGeneratorService) {}

  ngOnInit() {
    // console.log('Checkbox group component: ', this.config, this.checkboxFormGroup.value);
  }
}
