import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormFieldConfig, TextConfig } from './../../../form-template-model';

@Component({
  selector: 'csi-form-template-text-field-generator',
  templateUrl: './form-template-text-field-generator.component.html',
  styleUrls: ['./form-template-text-field-generator.component.css']
})
export class FormTemplateTextFieldGeneratorComponent implements OnInit {
  @Input() config: FormFieldConfig & { typeConfig: TextConfig };
  @Input() control: FormControl;
  @Input() formTemplateFieldKey: string;
  constructor() {}

  ngOnInit() {}
}
