import { ChangeDetectorRef, Component } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { version } from '../environments/appVersion';
import { CsiUserIdleService } from './core/authentication/csi-user-idle.service';
@Component({
  selector: 'csi-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

// TODO: Put Mouseflow stuff in its own service
export class AppComponent {
  private previousURL: string;
  private appVersion: string = version.versionValue;

  constructor(
    private router: Router,
    private csiUserIdleService: CsiUserIdleService,
    public translocoService: TranslocoService,
    public changeDetectorRef: ChangeDetectorRef
  ) {
    this.previousURL = window.location.pathname;
    this.addTagToMouseFlow(this.appVersion);
    this.subscribeToRouterEvents(this.router);
    this.csiUserIdleService.startIdleListener();
  }

  private subscribeToRouterEvents(router: Router) {
    router.events.subscribe(routerEvent => {
      this.handleNewPageView(routerEvent);
    });
  }

  private handleNewPageView(routerEvent: Event) {
    if (routerEvent instanceof NavigationEnd) {
      const newPageURL = routerEvent.urlAfterRedirects;
      if (this.previousURL !== newPageURL) {
        this.previousURL = newPageURL;

        window._mfq = window._mfq || [];
        window._mfq.push(['newPageView', newPageURL]);
      }
    }
  }
  private addTagToMouseFlow(tag: string) {
    window._mfq.push(['tag', tag]);
  }
}
