import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DerfsService } from './../../../questionnaires/derfs/derfs.service';

export interface MatCardQuestionDisplay {
  type: string;
  title: string;
  controls: any[];
  radioGroupSchema?: any;
  checkboxGroupSchema?: any;
  displayCondition?: {
    controlKey: string;
    displayOnValues: any[];
  };
}

@Injectable({
  providedIn: 'root'
})
export class PatientFileService {
  constructor(public derfsService: DerfsService) {}

  public patientFileSchema: { [key: string]: MatCardQuestionDisplay } = {
    gender: {
      type: 'checkboxGroup',
      title: 'Gender',
      controls: Object.keys(this.derfsService.genderList),
      checkboxGroupSchema: this.convertDerfsCheckboxListToCheckboxGroupSchema(
        this.derfsService.genderList
      )
    },
    childBirth: {
      type: 'radioGroup',
      title: 'Given birth to child',
      controls: ['childBirth'],
      radioGroupSchema: {
        formControlKey: 'childBirth',
        radioMap: this.derfsService.defaultRadioMap
      },
      displayCondition: { controlKey: 'female', displayOnValues: [false, null] }
    },
    ethnicity: {
      type: 'radioGroup',
      title: 'Ethnicity',
      controls: ['ethnicity', 'ethnicityOtherValue'],
      radioGroupSchema: {
        formControlKey: 'ethnicity',
        radioMap: this.derfsService.ethnicityRadioMap,
        toggleableFieldConfig: {
          fieldType: 'textarea',
          formControlKey: 'ethnicityOtherValue',
          triggeredByValue: 'others',
          required: true
        }
      }
    },
    education: {
      type: 'radioGroup',
      title: 'Education level',
      controls: ['education'],
      radioGroupSchema: {
        formControlKey: 'education',
        radioMap: this.derfsService.educationRadioMap
      }
    },
    familyHistory: {
      type: 'checkboxGroup',
      title: 'Family history',
      controls: Object.keys(this.derfsService.familyHistoryConditionsList),
      checkboxGroupSchema: this.convertDerfsCheckboxListToCheckboxGroupSchema(
        this.derfsService.familyHistoryConditionsList
      )
    },
    eyelashModification: {
      type: 'radioGroup',
      title: 'Used eyelash growth serum or eyelash extensions',
      controls: ['eyelashModification', 'eyelashModificationBrands'],
      radioGroupSchema: {
        formControlKey: 'eyelashModification',
        radioMap: this.derfsService.defaultRadioMap,
        toggleableFieldConfig: {
          fieldType: 'textarea',
          fieldTitle: 'Brands',
          formControlKey: 'eyelashModificationBrands',
          triggeredByValue: 'yes',
          required: true
        }
      }
    },
    sickBuildingSyndrome: {
      type: 'radioGroup',
      title: 'Diagnosed with Sick buidling syndrome in the past',
      controls: ['sickBuildingSyndrome'],
      radioGroupSchema: {
        formControlKey: 'sickBuildingSyndrome',
        radioMap: this.derfsService.sickBuildingSyndromeRadioMap
      }
    },
    hematopoieticStemCellTransplantation: {
      type: 'radioGroup',
      title: 'Transplanted hematopoietic stem cells',
      controls: ['hematopoieticStemCellTransplantation'],
      radioGroupSchema: {
        formControlKey: 'hematopoieticStemCellTransplantation',
        radioMap: this.derfsService.defaultRadioMap
      }
    },
    styeOrChalazion: {
      type: 'radioGroup',
      title: 'Has had a stye/chalazion in the past',
      controls: ['styeOrChalazion'],
      radioGroupSchema: {
        formControlKey: 'styeOrChalazion',
        radioMap: this.derfsService.styeOrChalazionRadioMap
      }
    },
    pastOcularHistory: {
      type: 'checkboxGroup',
      title: 'Past ocular history',
      controls: Object.keys(this.derfsService.pastOcularHistoryList),
      checkboxGroupSchema: this.convertDerfsCheckboxListToCheckboxGroupSchema(
        this.derfsService.pastOcularHistoryList
      )
    },
    pastSurgeries: {
      type: 'checkboxGroup',
      title: 'Past surgeries',
      controls: Object.keys(this.derfsService.surgeriesList),
      checkboxGroupSchema: this.convertDerfsCheckboxListToCheckboxGroupSchema(
        this.derfsService.surgeriesList
      )
    },
    medicalConditions: {
      type: 'checkboxGroup',
      title: 'Medical conditions',
      controls: Object.keys(this.derfsService.conditionsList),
      checkboxGroupSchema: this.convertDerfsCheckboxListToCheckboxGroupSchema(
        this.derfsService.conditionsList
      )
    }
  };

  buildFormGroup() {
    const tempFormGroup = new FormGroup({});
    Object.keys(this.patientFileSchema).forEach(patientFileKey => {
      const fieldConfig = this.patientFileSchema[patientFileKey];
      if (fieldConfig.controls) {
        for (const controlKey of fieldConfig.controls) {
          if (
            fieldConfig.radioGroupSchema &&
            fieldConfig.radioGroupSchema.toggleableFieldConfig &&
            fieldConfig.radioGroupSchema.toggleableFieldConfig.formControlKey &&
            fieldConfig.radioGroupSchema.toggleableFieldConfig.formControlKey === controlKey
          ) {
            const toggleableFieldConfig = fieldConfig.radioGroupSchema.toggleableFieldConfig;
            tempFormGroup.addControl(
              controlKey,
              new FormControl(
                this.getDefaultValue(fieldConfig),
                toggleableFieldConfig.required ? [Validators.required] : []
              )
            );
          } else {
            tempFormGroup.addControl(
              controlKey,
              new FormControl(this.getDefaultValue(fieldConfig))
            );
          }
          if (
            fieldConfig.checkboxGroupSchema &&
            fieldConfig.checkboxGroupSchema[controlKey] &&
            fieldConfig.checkboxGroupSchema[controlKey].toggleableField
          ) {
            const required = fieldConfig.checkboxGroupSchema[controlKey].required;
            tempFormGroup.addControl(
              controlKey + 'Value',
              new FormControl('', required ? [Validators.required] : [])
            );
          }
        }
      }
    });
    return tempFormGroup;
  }

  getDefaultValue(fieldConfig: MatCardQuestionDisplay) {
    switch (fieldConfig.type) {
      case 'radioGroup':
        return null;
      case 'checkboxGroup':
        return false;
      default:
        return null;
    }
  }

  public convertDerfsCheckboxListToCheckboxGroupSchema(list: any) {
    return Object.keys(list).reduce((acc, key) => {
      if (list[key].nonModifiableConfig && list[key].nonModifiableConfig.nonModifiable) {
        return {
          ...acc,
          [key]: {
            description: list[key].description,
            toggleableField: list[key].inputType ? true : false,
            required: list[key].required ? list[key].required : false
          }
        };
      } else {
        return acc;
      }
    }, {});
  }

  public transformPastOcularHistoryValue(
    currentMedicalHistoryValue: any,
    nonModifiableValue: any,
    appendValue: boolean
  ) {
    // if (!currentMedicalHistoryValue) return;
    // if (removeNonModifiableValue) {
    //   if (currentMedicalHistoryValue.includes(nonModifiableValue + '\n')) {
    //     return currentMedicalHistoryValue.replace(nonModifiableValue + '\n', '');
    //   }
    //   return currentMedicalHistoryValue.replace(nonModifiableValue, '');
    // }
    if (appendValue) {
      return currentMedicalHistoryValue
        ? currentMedicalHistoryValue + '\n' + nonModifiableValue
        : nonModifiableValue;
    }
    return currentMedicalHistoryValue;
  }
}
