import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { noSort } from 'src/app/core/api/model-utils';
import { PatientService } from 'src/app/core/api/patient.service';
import { FormErrorScrollingService } from 'src/app/shared/form-error-scrolling/form-error-scrolling.service';
import { GenderService } from 'src/app/shared/gender/gender.service';
import { NameFormat } from 'src/app/shared/shared-pipes/person-name.pipe';
import { DerfsService } from './../../../../questionnaires/derfs/derfs.service';
import { PatientFileService } from './../patient-file.service';

@Component({
  selector: 'csi-patient-file-modal',
  templateUrl: './patient-file-modal.component.html',
  styleUrls: ['./patient-file-modal.component.scss']
})
export class PatientFileModalComponent implements OnInit, AfterViewInit {
  @HostListener('window:keyup.esc') onKeyUp() {
    if (!this.dialogRef.disableClose) {
      this.dialogRef.close(this.savedFormData);
    }
  }

  public editing = false;
  public saving = false;
  public saved = false;
  public readonly NameFormat = NameFormat;

  public formGroup: FormGroup;
  public savedFormData = {};

  constructor(
    public dialogRef: MatDialogRef<PatientFileModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public patientService: PatientService,
    public derfsService: DerfsService,
    public genderService: GenderService,
    public patientFileService: PatientFileService,
    private hostElementRef: ElementRef,
    private formErrorScrollingService: FormErrorScrollingService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.dialogRef.backdropClick().subscribe(() => {
      if (!this.editing) {
        this.closeDialog();
      }
    });
    this.formGroup = this.patientFileService.buildFormGroup();

    setTimeout(() => {
      this.populatePatientFile();
    }, 0);
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  onEdit() {
    this.dialogRef.disableClose = true;
    this.editing = true;
  }

  onSave() {
    this.formGroup.markAllAsTouched();
    if (!this.formGroup.valid) {
      this.formErrorScrollingService.scrollToFirstInvalidControlInView(this.hostElementRef, true, {
        isHostScrollable: true,
        isHostMatStep: true
      });
      return;
    }
    this.saving = true;
    this.savedFormData = this.formGroup.value;
    this.dialogRef.disableClose = false;
    this.patientService
      .updatePatient({ id: this.data.id, nonModifiableData: JSON.stringify(this.savedFormData) })
      .subscribe(() => {
        this.editing = false;
        this.saving = false;
        this.saved = true;
      });
  }

  onClose() {
    if (this.editing) {
      this.formGroup.patchValue(this.savedFormData);
      this.formGroup.disable();
      this.editing = false;
      this.dialogRef.disableClose = false;
    } else {
      this.closeDialog();
    }
  }

  noSort = noSort;

  public populatePatientFile() {
    if (!this.data.nonModifiableData) return;
    this.formGroup.patchValue(JSON.parse(this.data.nonModifiableData));
    this.savedFormData = this.formGroup.value;
  }

  public closeDialog() {
    if (this.saved) {
      this.dialogRef.close(this.savedFormData);
    } else {
      this.dialogRef.close();
    }
  }
}
