import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTabGroup, MatTooltip } from '@angular/material';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
  MatDialogRef
} from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { ApolloError } from 'apollo-client';
import * as _ from 'lodash';
import { Observable, of, zip } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { Clinic, ClinicService } from 'src/app/core/api/clinic.service';
import { ErrorHandlerService } from 'src/app/core/api/error-handler.service';
import { Doctor, TemperatureUnit, UpdateReferralCenterInput } from './../../../API';
import { StaffService } from './../../core/api/staff.service';
import { ReferralService } from './../../referral/referral.service';
import { ClinicFormGroup } from './../../shared/consult-forms/clinic-form/clinic-form.model';
import { ClinicSetupService } from './clinic-setup.service';
import { LayoutsService } from './layouts/layouts.service';

interface Units {
  label: string;
  type: string;
  radioOptions?: { label: string; value: TemperatureUnit }[];
}

@Component({
  selector: 'csi-clinic-setup-modal',
  templateUrl: './clinic-setup-modal.component.html',
  styleUrls: ['./clinic-setup-modal.component.css']
})
export class ClinicSetupModalComponent implements OnInit, OnDestroy {
  @ViewChild('tabGroup', { static: true }) private tabGroup: MatTabGroup;

  public readonly clinicFormGroup = new ClinicFormGroup();

  unitCategories: Units[] = [
    {
      label: 'Temperature',
      type: 'Radio',
      radioOptions: [
        { label: 'Fahrenheit', value: TemperatureUnit.fahrenheit },
        { label: 'Celsius', value: TemperatureUnit.celsius }
      ]
    }
  ];

  public triedSave = false;
  public showEmrTab: boolean;
  public shouldShowDryEyeSetupTabs;
  public loggedInDoctor: Doctor;

  public intakeFormLink = `${window.location.protocol}//${window.location.host}/intake-form/${this.clinicSetupService.clinicId}?country=${this.clinicSetupService.clinic.country}`;

  public readonly copyLink = 'Copy Intake Form Link';
  public readonly copied = 'Copied';
  public toolTipContent = this.copyLink;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { clinic: Clinic; showEmr: boolean; isAdmin: boolean },
    private dialogRef: MatDialogRef<ClinicSetupModalComponent, undefined>,
    private clinicService: ClinicService,
    private errorHandlerService: ErrorHandlerService,
    public clinicSetupService: ClinicSetupService,
    private staffService: StaffService,
    private referralService: ReferralService,
    private layoutsService: LayoutsService,
    public translocoService: TranslocoService
  ) {}

  @ViewChild('tooltip', { static: false }) tooltip: MatTooltip;

  ngOnInit() {
    this.clinicFormGroup.patchFromClinic(this.data.clinic);
    if (this.clinicSetupService.isClinicAdmin) {
      this.clinicFormGroup.enable();
      this.clinicSetupService.clinicSetupFormGroup.enable();
    } else {
      this.clinicFormGroup.disable();
      this.clinicSetupService.clinicSetupFormGroup.disable();
    }

    this.loggedInDoctor = this.staffService.staff;
    this.shouldShowDryEyeSetupTabs =
      this.loggedInDoctor &&
      this.staffService.isDoctorApprovedForDryEyeSpecializedForm(this.loggedInDoctor);
  }

  ngOnDestroy() {}

  public static open(
    matDialogService: MatDialog,
    clinic: Clinic,
    showEmr: boolean,
    config: MatDialogConfig<never> = {}
  ) {
    return matDialogService.open(ClinicSetupModalComponent, {
      width: '1020px',
      maxWidth: '95vw',
      ...config,
      data: { clinic, showEmr }
    });
  }

  public save() {
    this.clinicFormGroup.submitted = true;
    this.triedSave = true;
    if (this.clinicFormGroup.invalid || this.clinicSetupService.clinicSetupFormGroup.invalid){
      this.makeSureFormErrorsAreVisible();
      return;
    }

    if (this.layoutsService.layoutsFormGroup.dirty) {
      this.clinicSetupService.clinicSetupFormGroup.controls.layouts.setValue(
        Object.values(this.layoutsService.layoutsFormGroup.value)
      );

      this.clinicSetupService.clinicSetupFormGroup.controls.layouts.markAsDirty();
    }

    if (this.clinicFormGroup.dirty || this.clinicSetupService.clinicSetupFormGroup.dirty) {
      this.clinicSetupService.isSaving = true;
      this.disableInteraction();

      zip(this.getClinicMutation(), this.getReferralCenterSyncMutation())
        .pipe(
          finalize(() => {
            this.clinicSetupService.isSaving = false;
            this.enableInteraction();
          })
        )
        .subscribe({
          error: (error: ApolloError) => {
            this.errorHandlerService.handleGraphQLError(error);
          },
          complete: () => {
            this.staffService.staff.clinic.logo = this.clinicFormGroup.controls.logo.value;
            this.layoutsService.setLayouts(
              this.clinicSetupService.clinicSetupFormGroup.controls.layouts.value
            );
            this.dialogRef.close();
          }
        });
    } else {
      this.dialogRef.close();
    }
  }

  getClinicMutation(): Observable<any> {
    if (!this.clinicSetupService.clinicSetupFormGroup.dirty) {
      return this.clinicService.updateClinic(
        this.clinicFormGroup.toUpdateClinicInput(this.data.clinic.id)
      );
    }

    if (!this.data.clinic.clinicSetup) {
      return this.clinicService.updateClinicAndCreateClinicSetup(
        this.clinicFormGroup.toUpdateClinicInput(this.data.clinic.id),
        this.clinicSetupService.clinicSetupFormGroup.getCreateClinicSetupInput(this.data.clinic.id)
      );
    }

    return this.clinicService.updateClinicWithClinicSetup(
      this.clinicFormGroup.toUpdateClinicInput(this.data.clinic.id),
      this.clinicSetupService.clinicSetupFormGroup.updateClinicSetupInput()
    );
  }

  getReferralCenterSyncMutation(): Observable<any> {
    if (!this.referralService.referralCenter) {
      return of(null);
    }

    let requiresSync = false;

    const updateReferralCenterInput: UpdateReferralCenterInput = {
      id: this.referralService.referralCenter.id
    };

    if (this.referralService.referralCenter.name !== this.clinicFormGroup.controls.name.value) {
      requiresSync = true;
      updateReferralCenterInput.name = this.clinicFormGroup.controls.name.value;
    }

    if (
      !_.isEqual(
        this.referralService.referralCenter.logo,
        this.clinicFormGroup.controls.logo.value
      ) &&
      (!this.clinicFormGroup.controls.logo.value ||
        !this.clinicFormGroup.controls.logo.value.__typename)
    ) {
      requiresSync = true;
      updateReferralCenterInput.logo = this.clinicFormGroup.controls.logo.value;
    }

    return requiresSync
      ? this.referralService.updateReferralCenter(updateReferralCenterInput).pipe(
          tap(() => {
            this.referralService.referralCenter.name = this.clinicFormGroup.controls.name.value;
            this.referralService.referralCenter.logo = this.clinicFormGroup.controls.logo.value;
          })
        )
      : of(null);
  }

  private makeSureFormErrorsAreVisible() {
    // TODO: Do this without hardcoding the tabs
    const invalidTab = this.clinicFormGroup.invalid
      ? this.clinicFormGroup.controls.emrIntegration.invalid
        ? 2
        : 0
      : -1;

    if (invalidTab !== -1) {
      this.tabGroup.selectedIndex = invalidTab;
    }
  }

  private enableInteraction() {
    this.dialogRef.disableClose = false;
    this.clinicFormGroup.enable();
  }

  private disableInteraction() {
    this.dialogRef.disableClose = true;
    this.clinicFormGroup.disable();
  }

  onCopy() {
    this.toolTipContent = this.copied;
    this.tooltip.show();
    setTimeout(() => (this.toolTipContent = this.copyLink), 3500);
    setTimeout(() => {
      this.tooltip.show();
    });
  }

  downloadQrCode(qrCode) {
    const qrCodeElement = qrCode.qrcElement.nativeElement.querySelector('img').src;
    const imageBlob = this.convertBase64ToBlob(qrCodeElement);
    const imageData = new Blob([imageBlob], { type: 'image/png' });
    const url = window.URL.createObjectURL(imageData);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'qrCode';
    link.click();
  }
  convertBase64ToBlob(base64Image: any) {
    const parts = base64Image.split(';base64,');

    const imageType = parts[0].split(':')[1];

    const decodedData = atob(parts[1]);
    const uInt8Array = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: imageType });
  }
}
