import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ApolloError } from 'apollo-client';
import cleanDeep from 'clean-deep';
import { iif, Observable, of, Subscription } from 'rxjs';
import { finalize, map, mergeMap } from 'rxjs/operators';
import { ConsentSource, FilterType, IntakeForm, QuestionnaireRequest } from 'src/API';

import { ErrorHandlerService } from 'src/app/core/api/error-handler.service';
import { Patient, PatientService } from 'src/app/core/api/patient.service';
import { StaffService } from 'src/app/core/api/staff.service';
import { FilterService } from 'src/app/filter/filter.service';
import { ClinicSetupService } from 'src/app/logged-in-navbar/clinic-setup-modal/clinic-setup.service';
import { PatientFormGroup } from 'src/app/shared/consult-forms/patient-form/patient-form.model';
import { PatientModalComponent } from 'src/app/shared/consult-forms/patient-modal/patient-modal.component';
import { LoadingSpinnerService } from 'src/app/shared/loading-spinner/loading-spinner.service';
import { HealthCardMode } from 'src/app/shared/location-select/location.model';
import { NameFormat, PersonNamePipe } from 'src/app/shared/shared-pipes/person-name.pipe';
import { IntakeFormPdfExportService } from '../intake-form-pdf-export.service';
import {
  allIntakeFormSchemas,
  generalButtonMap,
  IntakeFormGroupType,
  IntakeFormSchema,
  IntakeFormType,
  standardIntakeFormSchema
} from '../intake-form-schema';
import { IntakeFormService } from '../intake-form.service';
import { LinkWithSimilarPatientModalComponent } from '../link-with-similar-patient-modal/link-with-similar-patient-modal.component';
export interface SearchablePatient {
  patient: Partial<Patient>;
  score: number;
}
const patientKeyWeight: { [key: string]: any } = {
  firstName: 4,
  lastName: 4,
  address: 2,
  gender: 0,
  email: 4,
  phone: 4,
  healthCard: {
    province: 1,
    number: 8
  }
};

@Component({
  selector: 'csi-intake-form-view',
  templateUrl: './intake-form-view.component.html',
  styleUrls: ['./intake-form-view.component.css'],
  providers: [PersonNamePipe]
})
export class IntakeFormViewComponent implements OnInit, OnDestroy {
  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: IntakeForm,
    private intakeFormService: IntakeFormService,
    private clinicSetupService: ClinicSetupService,
    private staffService: StaffService,
    private intakeFormPdfService: IntakeFormPdfExportService,
    private loadingSpinnerService: LoadingSpinnerService,
    private errorHandlerService: ErrorHandlerService,
    private filterService: FilterService,
    private patientService: PatientService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog
  ) {}
  public isLoaded: boolean;
  public buttonMap = generalButtonMap;
  public intakeFormGroup = new FormGroup({});
  public intakeFormSubscription: Subscription;

  public intakeForm: IntakeForm;
  public loading: boolean;

  public isLinked: boolean;

  public patientFormGroup = new PatientFormGroup();
  public patient: Patient;
  public readonly NameFormat = NameFormat;
  public readonly HealthCardMode = HealthCardMode;
  public healthCardConfig: HealthCardMode;
  public questionnaire: QuestionnaireRequest;

  public intakeFormGroups = {};

  public intakeFormSchema: IntakeFormSchema;
  public standardIntakeFormSchema = standardIntakeFormSchema;
  public IntakeFormGroupType = IntakeFormGroupType;
  public intakeFormResponse = {};

  public intakeFormChangeTime: any;

  ngOnInit() {
    if (this.data) {
      this.intakeFormService.currentIntakeForm = this.data;
      this.intakeFormSchema =
        allIntakeFormSchemas[this.data['type']] || allIntakeFormSchemas[IntakeFormType.STANDARD];
    }

    this.activatedRoute.params.subscribe(routeParams => {
      if (
        !this.intakeFormService.currentIntakeForm ||
        (!this.data && routeParams.id !== this.intakeFormService.currentIntakeForm.id)
      ) {
        this.intakeFormService.getIntakeForm(routeParams.id).subscribe(incomingIntakeForm => {
          this.intakeForm =
            incomingIntakeForm !== undefined
              ? incomingIntakeForm
              : this.intakeFormService.currentIntakeForm;
          this.intakeFormResponse = incomingIntakeForm.response;
          this.loadIntakeFormResponse(this.intakeFormResponse, incomingIntakeForm['type']);
          this.isLinked = !!this.intakeFormService.currentIntakeForm.intakeFormPatientId;
          this.isLoaded = true;
        });
        return;
      }
      this.isLinked = !!this.intakeFormService.currentIntakeForm.intakeFormPatientId;
      this.intakeForm = this.intakeFormService.currentIntakeForm;
      this.intakeFormResponse =
        typeof this.intakeFormService.currentIntakeForm.response === 'string'
          ? JSON.parse(this.intakeFormService.currentIntakeForm.response)
          : this.intakeFormService.currentIntakeForm.response;
      this.loadIntakeFormResponse(
        this.intakeFormResponse,
        this.intakeFormService.currentIntakeForm['type']
      );
      this.isLoaded = true;
    });
  }

  loadIntakeFormResponse(intakeFormResponse: any, intakeFormType: string) {
    this.intakeFormSchema =
      allIntakeFormSchemas[intakeFormType] || allIntakeFormSchemas[IntakeFormType.STANDARD];

    this.buildIntakeFormGroup();
    this.intakeFormService.transformDeprecatedResponseKeys(
      intakeFormResponse,
      this.intakeFormSchema
    );
    // this.intakeFormGroup.reset();
    this.intakeFormGroup.patchValue(intakeFormResponse);
    this.patient = intakeFormResponse.patientInformation;
    this.questionnaire = intakeFormResponse.questionnaire;
  }

  buildIntakeFormGroup() {
    if (this.intakeFormSubscription) {
      this.intakeFormSubscription.unsubscribe();
    }

    this.intakeFormGroup = new FormGroup({});
    Object.keys(this.intakeFormSchema.groups).forEach(key => {
      let group = this.intakeFormSchema.groups[key];
      if (group.type === IntakeFormGroupType.PatientCard) {
        let pGroup = new PatientFormGroup();
        pGroup.setValidators(this.patientService.contactValidator);
        this.intakeFormGroup.addControl(key, pGroup);
      } else {
        this.intakeFormGroup.addControl(key, this.generateFormGroup(group.config));
      }
    });

    this.intakeFormSubscription = this.intakeFormGroup.valueChanges.subscribe(() => {
      this.intakeFormChangeTime = new Date().getTime();
    });
  }

  generateFormGroup(questionList: any) {
    let formGroup = new FormGroup({});
    Object.keys(questionList).forEach(question => {
      const validators = questionList[question].validators || null;
      const questions = questionList[question];
      if (questions.inputType.type === 'checkbox') {
        formGroup.addControl(question, new FormControl(false, validators));
      } else if (questions.inputType.type === 'checkboxGroup') {
        formGroup.addControl(
          question,
          new FormGroup(
            Object.keys(questions.inputType.checkboxes).reduce(
              (group, key) => ({ ...group, [key]: new FormControl() }),
              {}
            ),
            validators
          )
        );
      } else {
        formGroup.addControl(question, new FormControl(null, validators));
      }
    });
    return formGroup;
  }

  ngOnDestroy(): void {
    this.intakeFormService.currentIntakeForm = null;
    if (this.intakeFormSubscription) {
      this.intakeFormSubscription.unsubscribe();
    }
  }

  noSort() {
    return 0;
  }

  generatePDF() {
    this.loadingSpinnerService.show();
    this.intakeFormPdfService
      .downloadPDFForIntakeForm(this.intakeFormResponse, this.clinicSetupService.clinic, false)
      .pipe(
        finalize(() => {
          this.loadingSpinnerService.hide();
        })
      )
      .subscribe({
        error: (error: ApolloError) => {
          this.errorHandlerService.handleGraphQLError(error, true);
        }
      });
  }

  checkForSimilarPatients() {
    this.loadingSpinnerService.show();
    this.getMatchingPatients(this.patient)
      .pipe(
        mergeMap(result => {
          this.loadingSpinnerService.hide();

          if (result && result.length >= 1) {
            return LinkWithSimilarPatientModalComponent.openLinkWithSimilarPatientModel(
              this.dialog,
              result,
              this.patient
            ).afterClosed();
          }
          return of('new');
        }),
        mergeMap((patientInformation: Patient | 'new' | null) => {
          if (patientInformation === 'new') {
            return PatientModalComponent.open(
              this.dialog,
              this.staffService.staff,
              this.patient,
              null,
              null,
              true
            ).afterClosed();
          }
          return of(patientInformation);
        }),
        mergeMap((linkedPatientInformation: Patient) => {
          if (linkedPatientInformation) {
            this.loadingSpinnerService.show();

            return this.intakeFormService.updateIntakeFormWithPatientId({
              id: this.intakeFormService.currentIntakeForm.id,
              intakeFormPatientId: linkedPatientInformation.id
            });
          }

          return of(null);
        }),
        mergeMap(updatedIntakeForm => {
          if (updatedIntakeForm) {
            this.intakeFormService.currentIntakeForm = updatedIntakeForm;
            if (this.questionnaire.response) {
              return this.patientService
                .createLinkedPatientIntakeQuestionnaire(this.getQuestionnaireFormGroup())
                .pipe(map(() => updatedIntakeForm));
            }
            return of(updatedIntakeForm);
          }
          return of(null);
        })
      )
      .subscribe(intakeForm => {
        if (intakeForm) {
          this.isLinked = true;
        }
        this.loadingSpinnerService.hide();
      });
  }

  public openLinkedPatient() {
    this.patientService
      .getPatient(this.intakeFormService.currentIntakeForm.intakeFormPatientId)
      .subscribe(patient => {
        if (patient) {
          PatientModalComponent.open(
            this.dialog,
            this.staffService.staff,
            patient,
            null,
            null,
            null,
            true
          );
        }
      });
  }

  public openPatientProfile() {
    this.router.navigateByUrl(
      `/patients/${this.intakeFormService.currentIntakeForm.intakeFormPatientId}`
    );
  }

  private getMatchingPatients(patient: Patient): Observable<Partial<SearchablePatient>[]> {
    this.loading = true;
    return iif(
      () => !!this.staffService.staff && !!this.clinicSetupService.clinic,
      this.filterService.queryFilterData(FilterType.patient, null),
      of(null)
    ).pipe(
      map((result: Partial<Patient[]>) => {
        return this.filterMatchList(patient, result);
      })
    );
  }
  private filterMatchList(patient: Patient, results?: Patient[]): Partial<SearchablePatient>[] {
    const cleanedPatientData = cleanDeep(patient);
    const cleanedPatientList = cleanDeep(results);
    return this.similarPatients(cleanedPatientData, cleanedPatientList);
  }

  private similarPatients(
    cleanedPatientData: Partial<Patient>,
    cleanedPatientList: Patient[]
  ): Partial<SearchablePatient>[] {
    const similarityThreshold = 8;
    return cleanedPatientList
      .map(patient => {
        const score = this.getSortWeight(cleanedPatientData, patient);
        return { patient, score };
      })
      .filter(patient => {
        if (patient.score >= similarityThreshold) {
          return patient;
        }
      })
      .sort((a, b) => b.score - a.score);
  }

  public getSortWeight(
    patientData: Partial<Patient>,
    patientToCompare: Patient,
    weight: any = patientKeyWeight
  ): number {
    let score = 0;
    for (const key in weight) {
      if (patientToCompare.hasOwnProperty(key) && patientData.hasOwnProperty(key)) {
        if (
          typeof patientData[key] === 'object' &&
          typeof patientToCompare[key] === 'object' &&
          typeof weight[key] === 'object'
        ) {
          score += this.getSortWeight(patientData[key], patientToCompare[key], weight[key]);
        } else if (
          this.areEqual(patientData[key as keyof Patient], patientToCompare[key as keyof Patient])
        ) {
          score += weight[key];
        }
      }
    }
    return score;
  }

  public static openIntakeFormDialog(matDialogService: MatDialog, patientIntakeForm: IntakeForm) {
    return matDialogService.open(IntakeFormViewComponent, {
      width: '60rem',
      height: '95dvh',
      maxHeight: '100dvh',
      maxWidth: '100dvh',
      data: patientIntakeForm
    });
  }

  public closeDialogBox() {
    this.dialog.closeAll();
  }

  private areEqual(value1: any, value2: any): boolean {
    if (typeof value1 === 'string' && typeof value2 === 'string') {
      return value1.trim().toLowerCase() === value2.trim().toLowerCase();
    }
    return value1 === value2;
  }

  private getQuestionnaireFormGroup() {
    let questionnaireResponse = {};
    if (this.questionnaire.response)
      questionnaireResponse = {
        answers: JSON.parse(this.questionnaire.response.answers),
        scores: JSON.parse(this.questionnaire.response.scores),
        consent: {
          consentsToPrivacyForm: true,
          consentsToResearch: true,
          agreedToTerms: true,
          consentSource: ConsentSource.Patient
        }
      };
    return new FormBuilder().group({
      type: this.questionnaire.type ? [this.questionnaire.type] : [],
      response: JSON.stringify(questionnaireResponse),
      questionnaireRequestPatientId: this.intakeFormService.currentIntakeForm.intakeFormPatientId,
      questionnaireRequestClinicId: this.intakeFormService.currentIntakeForm.intakeFormClinicId,
      email: this.patient.email || null,
      mobile: this.patient.phone || null,
      country: (this.patient.healthCard && this.patient.healthCard.country) || null,
      completedAt: this.intakeFormService.currentIntakeForm.createdAt,
      config: JSON.stringify({
        intakeFormId: this.intakeFormService.currentIntakeForm.id
      })
    }).value;
  }
}
