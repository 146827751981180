import { Injectable } from '@angular/core';
import { Compare, FormFieldConfig, TriggerGroupType } from '../form-template-model';

@Injectable({
  providedIn: 'root'
})
export class FormTemplateGeneratorService {
  public supportingFields: { [key: string]: FormFieldConfig };
  public readOnly = false;

  constructor() {
    // [oliver_combined as FormTemplateSchema].forEach(questionnaire => {
    //   console.log(questionnaire);
    //   Object.values(questionnaire.templateGroups).forEach((group: FormTemplateGroup) => {
    //     console.log(group);
    //     Object.values(group.formFields).forEach((field, j) => {
    //       field.sort = j;
    //       Object.values(field.typeConfig['valuesConfig'] || []).forEach(
    //         (valueConfig: ValueConfig, i) => {
    //           valueConfig.sort = i;
    //         }
    //       );
    //     });
    //   });
    // });
    // console.log(JSON.stringify(oliver_combined));
    // console.log(JSON.stringify(oliverQuestionnaire));
    // console.log(JSON.stringify(patientIntakeQuestion));
  }

  evaluateTrigger(a: any, b: any, compareOperator: Compare) {
    a = this.convertToLowerCaseIfString(a);
    b = this.convertToLowerCaseIfString(b);
    switch (compareOperator) {
      case Compare.equals:
        return a === b;
      case Compare.lessThan:
        return a < b;
      case Compare.greaterThan:
        return a > b;
      case Compare.greaterThanOrEqual:
        return a >= b;
      case Compare.lessThanOrEqual:
        return a <= b;
      default:
        console.log('unknown comparison operator');
        return false;
    }
  }

  compareByTriggerGroupType(a: boolean, b: boolean, triggerGroupType: TriggerGroupType) {
    switch (triggerGroupType) {
      case TriggerGroupType.and:
        return a && b;
      case TriggerGroupType.or:
        return a || b;
      default:
        console.log('unknown trigger group operator');
        return false;
    }
  }

  convertToLowerCaseIfString(s: any) {
    if (typeof s === 'string') {
      return s.toLowerCase();
    }
    return s;
  }
}
