import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { SignupError } from 'src/app/core/authentication/models/error-models/SignupError';
import { phoneNumberAndCountryCodeValidator } from 'src/app/shared/shared-validators/phone-number-validator';
import { PasswordMatchValidator } from '../../PasswordMatchValidator';
import { AuthenticatorMode, matSpinnerOptions } from '../authenticator-modal.component';

@Component({
  selector: 'csi-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['../authenticator-modal.component.css', './create-account.component.css']
})
export class CreateAccountComponent implements OnInit {
  @Input() authenticatorMode$: BehaviorSubject<AuthenticatorMode>;
  @Input() emailControl: FormControl;

  public AuthenticatorMode = AuthenticatorMode;
  public errorResponse: SignupError;
  public SignupError = SignupError;
  public formGroup: FormGroup;
  public submitted = false;
  public isAccountCreated = false;
  public matSpinnerOptions = { ...matSpinnerOptions };

  constructor(
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    this.matSpinnerOptions.text = 'Create Account';
  }

  ngOnInit() {
    const passwordControl = new FormControl('', [Validators.required, Validators.minLength(8)]);
    this.formGroup = this.formBuilder.group(
      {
        email: this.emailControl,
        password: passwordControl,
        confirmPassword: new FormControl('', [
          PasswordMatchValidator.validatorForPasswordControl(passwordControl)
        ]),
        phone: new FormControl('', Validators.pattern('[0-9]{10}')),
        countryCode: new FormControl('')
      },
      { validator: phoneNumberAndCountryCodeValidator('phone', 'countryCode') }
    );
  }

  public submitSignUp() {
    this.formGroup.controls.countryCode.markAsTouched();

    this.submitted = true;

    if (this.formGroup.valid) {
      this.matSpinnerOptions.active = true;
      this.authenticationService
        .signUp(
          this.formGroup.value.email,
          this.formGroup.value.password,
          this.formGroup.value.phone
            ? this.formGroup.value.countryCode + this.formGroup.value.phone
            : null,
          this.router.url
        )
        .pipe(finalize(() => (this.matSpinnerOptions.active = false)))
        .subscribe(
          () => {
            this.isAccountCreated = true;
            this.authenticatorMode$.next(AuthenticatorMode.VerifyUser);
          },
          error => {
            this.errorResponse = error;
          }
        );
    }
  }

  clearServerErrors() {
    this.errorResponse = undefined;
  }
}
