import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { noSort } from 'src/app/core/api/model-utils';

@Component({
  selector: 'csi-checkbox-question',
  templateUrl: './checkbox-question.component.html',
  styleUrls: ['./checkbox-question.component.css']
})

// export interface CheckboxSchema {
//   description: string;
//   toggleableField?: boolean;
//   type?: string;
// }
export class CheckboxQuestionComponent implements OnInit {
  @Input() checkboxGroupSchema;
  @Input() formGroup: FormGroup;
  @Input() viewOnly = false;

  @Output() change = new EventEmitter<string>();

  public noSort = noSort;
  public atLeastOneCheckboxSelected = false;
  public selectedCheckboxesCounter = 0;

  constructor() {}

  ngOnInit() {
    Object.keys(this.checkboxGroupSchema).forEach(controlKey => {
      this.addToFormGroupIfKeyDoesNotExist(controlKey, this.formGroup);
      if (this.formGroup.controls[controlKey].value === true) {
        this.selectedCheckboxesCounter++;
        this.atLeastOneCheckboxSelected = true;
      }
      const checkboxSchema = this.checkboxGroupSchema[controlKey];

      if (checkboxSchema.toggleableField) {
        this.addToFormGroupIfKeyDoesNotExist(
          controlKey + 'Value',
          this.formGroup,
          checkboxSchema.required ? true : false
        );
        this.formGroup.controls[controlKey].valueChanges.subscribe(val => {
          val
            ? this.formGroup.controls[controlKey + 'Value'].enable()
            : this.formGroup.controls[controlKey + 'Value'].disable();
        });
        if (checkboxSchema.required && this.formGroup.controls[controlKey].value === false) {
          this.formGroup.controls[controlKey + 'Value'].disable();
        }
      }
    });
  }

  public onChange(key: string) {
    this.change.emit(key);
    if (this.formGroup.controls[key].value === true) {
      this.selectedCheckboxesCounter++;
    } else {
      this.selectedCheckboxesCounter--;
    }
    if (this.selectedCheckboxesCounter > 0) {
      this.atLeastOneCheckboxSelected = true;
    } else if (this.selectedCheckboxesCounter === 0) {
      this.atLeastOneCheckboxSelected = false;
    } else {
      console.log('Selected checkboxes less than zero error!');
    }
  }

  addToFormGroupIfKeyDoesNotExist(key, formGroup: FormGroup, required?: boolean) {
    if (!formGroup.controls[key]) {
      formGroup.addControl(key, new FormControl(null, required ? Validators.required : null));
    }
  }
}
