import { AbstractControl, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { CustomValidator } from './intake-form-validators';

export const intakeFormPatientInformation = {
  // formGroup: test,
  showResearchConsent: false,
  canLookupPatient: false,
  showInCard: true,
  country: 'Canada'
};
export const speedIIQuestion = {
  readonly: false,
  intakeForm: true,
  customQuestions: ['personality']
};

export const patientGeneralHealthQuestion: QuestionType = {
  occupation: {
    friendlyName: 'Occupation',
    inputType: {
      type: 'input'
    },
    validators: [CustomValidator.required]
  },
  hobbies: {
    friendlyName: 'Hobbies',
    inputType: {
      type: 'input'
    },
    validators: [CustomValidator.required]
  },
  wearGlasses: {
    friendlyName: 'Do you wear glasses?',
    inputType: {
      type: 'radio',
      buttonMap: 'generalRadioButton'
    },
    validators: [CustomValidator.required]
  },

  contacts: {
    friendlyName: 'Do you wear contact lenses?',
    inputType: {
      type: 'radio',
      buttonMap: 'thirdGeneralRadioButton'
    },
    validators: [CustomValidator.required]
  },
  contactLensdaysPerWeek: {
    friendlyName: 'Wearing days per week',
    inputType: {
      type: 'slider',
      min: 1,
      max: 7,
      step: 1
    },
    validators: [CustomValidator.required],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched =
        formGroup.controls.contacts.value === 'yes' || formGroup.controls.contacts.value === 'past';

      basicToggleFn(
        formGroup.controls.contactLensdaysPerWeek,
        conditionMatched,
        'contactLensdaysPerWeek'
      );

      return conditionMatched;
    }
  },
  contactLensType: {
    friendlyName: 'Type of contact lenses',
    inputType: {
      type: 'radio',
      buttonMap: 'contactLensTypeRadioButton'
    },
    validators: [CustomValidator.required],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched =
        formGroup.controls.contacts.value === 'yes' || formGroup.controls.contacts.value === 'past';

      basicToggleFn(formGroup.controls.contactLensType, conditionMatched, 'contactLensType');

      return conditionMatched;
    }
  },
  contactLensDailyDisposable: {
    friendlyName: 'Are your contacts daily disposable?',
    inputType: {
      type: 'radio',
      buttonMap: 'generalRadioButton'
    },
    validators: [CustomValidator.required],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.contactLensType.value === 'soft';

      basicToggleFn(
        formGroup.controls.contactLensDailyDisposable,
        conditionMatched,
        'contactLensDailyDisposable'
      );

      return conditionMatched;
    }
  },
  contactLensesProblem: {
    friendlyName: 'Do you have problems seeing with your glasses/contact lenses?',
    inputType: {
      type: 'radio',
      buttonMap: 'generalRadioButton'
    },
    validators: [CustomValidator.required],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched =
        formGroup.controls.wearGlasses.value === 'yes' ||
        formGroup.controls.contacts.value === 'yes';

      basicToggleFn(
        formGroup.controls.contactLensesProblem,
        conditionMatched,
        'contactLensesProblem'
      );

      return conditionMatched;
    }
  },
  lastEyeExam: {
    friendlyName: 'Date of last eye exam',
    inputType: {
      type: 'date'
    },
    validators: []
  },
  // allergies: {
  //   friendlyName: 'Allergies',
  //   inputType: {
  //     type: 'input'
  //   },
  //   validators: [CustomValidator.required]
  // },
  issueInEyeExam: {
    friendlyName: 'Were there any issues arising from this or any previous eye examination?',
    inputType: {
      type: 'radio',
      buttonMap: 'generalRadioButton'
    },
    validators: [CustomValidator.required]
  },
  previousProblemSpecify: {
    friendlyName: 'If yes, please state issues',
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    inputType: {
      type: 'input'
    },
    validators: [CustomValidator.required],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.issueInEyeExam.value === 'yes';

      basicToggleFn(
        formGroup.controls.previousProblemSpecify,
        conditionMatched,
        'previousProblemSpecify'
      );

      return conditionMatched;
    }
  },
  medications: {
    friendlyName: 'Medications',
    inputType: {
      type: 'medication'
    }
  }
};

export const eyeSurgeryQuestion: QuestionType = {
  glaucomaSurgery: {
    friendlyName: 'Glaucoma surgery',
    inputType: {
      type: 'checkbox'
    }
  },
  cataractSurgery: {
    friendlyName: 'Cataract surgery',
    inputType: {
      type: 'checkbox'
    }
  },
  strabismusSurgery: {
    friendlyName: 'Strabismus surgery',
    inputType: {
      type: 'checkbox'
    }
  },
  ptosisSurgery: {
    friendlyName: 'Ptosis surgery',
    inputType: {
      type: 'checkbox'
    }
  },
  retinalSurgery: {
    friendlyName: 'Retinal surgery',
    inputType: {
      type: 'checkbox'
    }
  },
  refractiveSurgery: {
    friendlyName: 'Refractive surgery (laser eye surgery)',
    inputType: {
      type: 'checkbox'
    }
  },
  eyelidSurgery: {
    friendlyName: 'Eyelid surgery',
    inputType: {
      type: 'checkbox'
    }
  },
  ocularSurfaceSurgery: {
    friendlyName: 'Ocular surface surgery',
    inputType: {
      type: 'checkbox'
    }
  },
  surgeryOther: {
    friendlyName: 'Other',
    inputType: {
      type: 'checkbox'
    }
  },
  surgeryOtherSpecify: {
    friendlyName: 'Please specify',
    inputType: {
      type: 'input'
    },
    validators: [CustomValidator.required],
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.surgeryOther.value;

      basicToggleFn(
        formGroup.controls.surgeryOtherSpecify,
        conditionMatched,
        'surgeryOtherSpecify'
      );

      return conditionMatched;
    }
  }

  // diabetesType: {
  //   friendlyName: 'Type',
  //   cssClass: {
  //     questionContainer: 'radio-sub-question-container ',
  //     questionCss: 'radio-sub-question-label',
  //     inputTypeCss: 'sub-question-input-type'
  //   },
  //   inputType: {
  //     type: 'radio',
  //     buttonMap: 'types'
  //   },
  //   validators: [CustomValidator.required],
  //   toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
  //     const conditionMatched = formGroup.controls.diabetes.value;

  //     basicToggleFn(formGroup.controls.diabetesType, conditionMatched, 'diabetesType');

  //     return conditionMatched;
  //   }
  // },
};

export const medicalConditions: QuestionType = {
  // friendlyName:
  //   'Do you have any of the following conditions, or have had them in the past? (Select all that apply)',
  anxiety: {
    friendlyName: 'Anxiety',
    inputType: {
      type: 'checkbox'
    }
  },
  anxietyYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.anxiety.value;

      basicToggleFn(
        formGroup.controls.anxietyYearOfDiagnosis,
        conditionMatched,
        'anxietyYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  migraine: {
    friendlyName: 'Migraine',
    inputType: {
      type: 'checkbox'
    }
  },
  migraineYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.migraine.value;

      basicToggleFn(
        formGroup.controls.migraineYearOfDiagnosis,
        conditionMatched,
        'migraineYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  asthma: {
    friendlyName: 'Asthma',
    inputType: {
      type: 'checkbox'
    }
  },
  asthmaYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.asthma.value;

      basicToggleFn(
        formGroup.controls.asthmaYearOfDiagnosis,
        conditionMatched,
        'asthmaYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  seasonalAllergies: {
    friendlyName: 'Seasonal allergies',
    inputType: {
      type: 'checkbox'
    }
  },
  seasonalAllergiesYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.seasonalAllergies.value;

      basicToggleFn(
        formGroup.controls.seasonalAllergiesYearOfDiagnosis,
        conditionMatched,
        'seasonalAllergiesYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  seasonalAllergiesSpecify: {
    friendlyName: 'Please specify',
    inputType: {
      type: 'input'
    },
    validators: [CustomValidator.required],
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.seasonalAllergies.value;

      basicToggleFn(
        formGroup.controls.seasonalAllergiesSpecify,
        conditionMatched,
        'seasonalAllergiesSpecify'
      );

      return conditionMatched;
    }
  },
  drugAllergies: {
    friendlyName: 'Drug allergies',
    inputType: {
      type: 'checkbox'
    }
  },
  drugAllergiesYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.drugAllergies.value;

      basicToggleFn(
        formGroup.controls.drugAllergiesYearOfDiagnosis,
        conditionMatched,
        'drugAllergiesYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  drugAllergiesSpecify: {
    friendlyName: 'Please specify',
    inputType: {
      type: 'input'
    },
    validators: [CustomValidator.required],
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.drugAllergies.value;

      basicToggleFn(
        formGroup.controls.drugAllergiesSpecify,
        conditionMatched,
        'drugAllergiesSpecify'
      );

      return conditionMatched;
    }
  },
  environmentalAllergies: {
    friendlyName: 'Environmental allergies',
    inputType: {
      type: 'checkbox'
    }
  },
  environmentalAllergiesYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.environmentalAllergies.value;

      basicToggleFn(
        formGroup.controls.environmentalAllergiesYearOfDiagnosis,
        conditionMatched,
        'environmentalAllergiesYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  environmentalAllergiesSpecify: {
    friendlyName: 'Please specify',
    inputType: {
      type: 'input'
    },
    validators: [CustomValidator.required],
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.environmentalAllergies.value;

      basicToggleFn(
        formGroup.controls.environmentalAllergiesSpecify,
        conditionMatched,
        'environmentalAllergiesSpecify'
      );

      return conditionMatched;
    }
  },
  sunAllergy: {
    friendlyName: 'Sun allergy, or rash on sun exposure',
    inputType: {
      type: 'checkbox'
    }
  },
  sunAllergyYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.sunAllergy.value;

      basicToggleFn(
        formGroup.controls.sunAllergyYearOfDiagnosis,
        conditionMatched,
        'sunAllergyYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  sunAllergySpecify: {
    friendlyName: 'Please specify',
    inputType: {
      type: 'input'
    },
    validators: [CustomValidator.required],
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.sunAllergy.value;

      basicToggleFn(formGroup.controls.sunAllergySpecify, conditionMatched, 'sunAllergySpecify');

      return conditionMatched;
    }
  },
  chronicOBstructivePulmonaryDisease: {
    friendlyName: 'Chronic Obstructive Pulmonary Disease (COPD)',
    inputType: {
      type: 'checkbox'
    }
  },
  chronicOBstructivePulmonaryDiseaseYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.chronicOBstructivePulmonaryDisease.value;

      basicToggleFn(
        formGroup.controls.chronicOBstructivePulmonaryDiseaseYearOfDiagnosis,
        conditionMatched,
        'chronicOBstructivePulmonaryDiseaseYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  abnormalBloodClotting: {
    friendlyName: 'Abnormal blood clotting',
    inputType: {
      type: 'checkbox'
    }
  },
  abnormalBloodClottingYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.abnormalBloodClotting.value;

      basicToggleFn(
        formGroup.controls.abnormalBloodClottingYearOfDiagnosis,
        conditionMatched,
        'abnormalBloodClottingYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  otherSkinConditions: {
    friendlyName: 'Other skin condition(s)',
    inputType: {
      type: 'checkbox'
    }
  },
  otherSkinConditionsYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.otherSkinConditions.value;

      basicToggleFn(
        formGroup.controls.otherSkinConditionsYearOfDiagnosis,
        conditionMatched,
        'otherSkinConditionsYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  otherSkinConditionsSpecify: {
    friendlyName: 'Please specify',
    inputType: {
      type: 'input'
    },
    validators: [CustomValidator.required],
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.otherSkinConditions.value;

      basicToggleFn(
        formGroup.controls.otherSkinConditionsSpecify,
        conditionMatched,
        'otherSkinConditionsSpecify'
      );

      return conditionMatched;
    }
  },
  geneticConditions: {
    friendlyName: 'Genetic condition(s)',
    inputType: {
      type: 'checkbox'
    }
  },
  geneticConditionsYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.geneticConditions.value;

      basicToggleFn(
        formGroup.controls.geneticConditionsYearOfDiagnosis,
        conditionMatched,
        'geneticConditionsYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  geneticConditionsSpecify: {
    friendlyName: 'Please specify',
    inputType: {
      type: 'input'
    },
    validators: [CustomValidator.required],
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.geneticConditions.value;

      basicToggleFn(
        formGroup.controls.geneticConditionsSpecify,
        conditionMatched,
        'geneticConditionsSpecify'
      );

      return conditionMatched;
    }
  },
  seizureDisorder: {
    friendlyName: 'Seizure disorder',
    inputType: {
      type: 'checkbox'
    }
  },
  seizureDisorderYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.seizureDisorder.value;

      basicToggleFn(
        formGroup.controls.seizureDisorderYearOfDiagnosis,
        conditionMatched,
        'seizureDisorderYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  graftHostDisease: {
    friendlyName: 'Graft versus host disease (GVHD)',
    inputType: {
      type: 'checkbox'
    }
  },
  graftHostDiseaseYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.graftHostDisease.value;

      basicToggleFn(
        formGroup.controls.graftHostDiseaseYearOfDiagnosis,
        conditionMatched,
        'graftHostDiseaseYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  pigmentationChangeInFace: {
    friendlyName: 'Pigmentation change in the skin of the face.',
    inputType: {
      type: 'checkbox'
    }
  },
  pigmentationChangeInFaceYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.pigmentationChangeInFace.value;

      basicToggleFn(
        formGroup.controls.pigmentationChangeInFaceYearOfDiagnosis,
        conditionMatched,
        'pigmentationChangeInFaceYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  fibromyalgia: {
    friendlyName: 'Fibromyalgia',
    inputType: {
      type: 'checkbox'
    }
  },
  fibromyalgiaYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.fibromyalgia.value;

      basicToggleFn(
        formGroup.controls.fibromyalgiaYearOfDiagnosis,
        conditionMatched,
        'fibromyalgiaYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  sensationOfHeatCondition: {
    friendlyName: 'Condition affecting sensation of heat',
    inputType: {
      type: 'checkbox'
    }
  },
  sensationOfHeatConditionYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.sensationOfHeatCondition.value;

      basicToggleFn(
        formGroup.controls.sensationOfHeatConditionYearOfDiagnosis,
        conditionMatched,
        'sensationOfHeatConditionYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  heatIllness: {
    friendlyName: 'Heat illness',
    inputType: {
      type: 'checkbox'
    }
  },
  heatIllnessYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.heatIllness.value;

      basicToggleFn(
        formGroup.controls.heatIllnessYearOfDiagnosis,
        conditionMatched,
        'heatIllnessYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  heaterDermatitis: {
    friendlyName: 'Heater dermatitis',
    inputType: {
      type: 'checkbox'
    }
  },
  heaterDermatitisYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.heaterDermatitis.value;

      basicToggleFn(
        formGroup.controls.heaterDermatitisYearOfDiagnosis,
        conditionMatched,
        'heaterDermatitisYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  keloidScars: {
    friendlyName: 'History of keloid scars (difficult and blistered healing)',
    inputType: {
      type: 'checkbox'
    }
  },
  keloidScarsYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.keloidScars.value;

      basicToggleFn(
        formGroup.controls.keloidScarsYearOfDiagnosis,
        conditionMatched,
        'keloidScarsYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  hypertension: {
    friendlyName: 'Hypertension',
    inputType: {
      type: 'checkbox'
    }
  },
  hypertensionYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.hypertension.value;

      basicToggleFn(
        formGroup.controls.hypertensionYearOfDiagnosis,
        conditionMatched,
        'hypertensionYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  glaucoma: {
    friendlyName: 'Glaucoma',
    inputType: {
      type: 'checkbox'
    }
  },
  glaucomaYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.glaucoma.value;

      basicToggleFn(
        formGroup.controls.glaucomaYearOfDiagnosis,
        conditionMatched,
        'glaucomaYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  diabetes: {
    friendlyName: 'Diabetes',
    inputType: {
      type: 'checkbox'
    }
  },
  diabetesYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.diabetes.value;

      basicToggleFn(
        formGroup.controls.diabetesYearOfDiagnosis,
        conditionMatched,
        'diabetesYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  diabetesType: {
    friendlyName: 'Type',
    cssClass: {
      questionContainer: 'radio-sub-question-container ',
      questionCss: 'radio-sub-question-label',
      inputTypeCss: 'sub-question-input-type'
    },
    inputType: {
      type: 'radio',
      buttonMap: 'types'
    },
    validators: [CustomValidator.required],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.diabetes.value;

      basicToggleFn(formGroup.controls.diabetesType, conditionMatched, 'diabetesType');

      return conditionMatched;
    }
  },
  meanBloodGlucose: {
    friendlyName: 'Mean blood glucose (mmol/L)',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.required],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.diabetes.value;

      basicToggleFn(formGroup.controls.meanBloodGlucose, conditionMatched, 'meanBloodGlucose');

      return conditionMatched;
    }
  },
  insulin: {
    friendlyName: 'Insulin',
    cssClass: {
      questionContainer: 'radio-sub-question-container ',
      questionCss: 'radio-sub-question-label',
      inputTypeCss: 'sub-question-input-type'
    },
    inputType: {
      type: 'radio',
      buttonMap: 'generalRadioButton'
    },
    validators: [CustomValidator.required],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.diabetes.value;

      basicToggleFn(formGroup.controls.insulin, conditionMatched, '');

      return conditionMatched;
    }
  },
  hypercholesterolemia: {
    friendlyName: 'Hypercholesterolemia',
    inputType: {
      type: 'checkbox'
    }
  },
  hypercholesterolemiaYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.hypercholesterolemia.value;

      basicToggleFn(
        formGroup.controls.hypercholesterolemiaYearOfDiagnosis,
        conditionMatched,
        'hypercholesterolemiaYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  eczema: {
    friendlyName: 'Eczema',
    inputType: {
      type: 'checkbox'
    }
  },
  eczemaYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.eczema.value;

      basicToggleFn(
        formGroup.controls.eczemaYearOfDiagnosis,
        conditionMatched,
        'eczemaYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  acne: {
    friendlyName: 'Acne',
    inputType: {
      type: 'checkbox'
    }
  },
  acneYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.acne.value;

      basicToggleFn(
        formGroup.controls.acneYearOfDiagnosis,
        conditionMatched,
        'acneYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  rosacea: {
    friendlyName: 'Rosacea',
    inputType: {
      type: 'checkbox'
    }
  },
  rosaceaYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.rosacea.value;

      basicToggleFn(
        formGroup.controls.rosaceaYearOfDiagnosis,
        conditionMatched,
        'rosaceaYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  psoriasis: {
    friendlyName: 'Psoriasis',
    inputType: {
      type: 'checkbox'
    }
  },
  psoriasisYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.psoriasis.value;

      basicToggleFn(
        formGroup.controls.psoriasisYearOfDiagnosis,
        conditionMatched,
        'psoriasisYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  lupus: {
    friendlyName: 'Lupus erythematosus',
    inputType: {
      type: 'checkbox'
    }
  },
  lupusYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.lupus.value;

      basicToggleFn(
        formGroup.controls.lupusYearOfDiagnosis,
        conditionMatched,
        'lupusYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  arthritis: {
    friendlyName: 'Arthritis',
    inputType: {
      type: 'checkbox'
    }
  },
  arthritisYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.arthritis.value;

      basicToggleFn(
        formGroup.controls.arthritisYearOfDiagnosis,
        conditionMatched,
        'arthritisYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  rheumatoidArthritis: {
    friendlyName: 'Rheumatoid arthritis',
    inputType: {
      type: 'checkbox'
    }
  },
  rheumatoidArthritisYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.rheumatoidArthritis.value;

      basicToggleFn(
        formGroup.controls.rheumatoidArthritisYearOfDiagnosis,
        conditionMatched,
        'rheumatoidArthritisYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  osteoporosis: {
    friendlyName: 'Osteoporosis',
    inputType: {
      type: 'checkbox'
    }
  },
  osteoporosisYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.osteoporosis.value;

      basicToggleFn(
        formGroup.controls.osteoporosisYearOfDiagnosis,
        conditionMatched,
        'osteoporosisYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  sclerosis: {
    friendlyName: 'Sclerosis',
    inputType: {
      type: 'checkbox'
    }
  },
  sclerosisYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.sclerosis.value;

      basicToggleFn(
        formGroup.controls.sclerosisYearOfDiagnosis,
        conditionMatched,
        'sclerosisYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  thyroid: {
    friendlyName: 'Thyroid disease',
    inputType: {
      type: 'checkbox'
    }
  },
  thyroidYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.thyroid.value;

      basicToggleFn(
        formGroup.controls.thyroidYearOfDiagnosis,
        conditionMatched,
        'thyroidYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  thyroidDescribe: {
    friendlyName: 'Please describe',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.required],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.thyroid.value;

      basicToggleFn(formGroup.controls.thyroidDescribe, conditionMatched, 'thyroidDescribe');

      return conditionMatched;
    }
  },
  chronicPainSyndrome: {
    friendlyName: 'Chronic widespread pain syndrome',
    inputType: {
      type: 'checkbox'
    }
  },
  chronicPainSyndromeYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.chronicPainSyndrome.value;

      basicToggleFn(
        formGroup.controls.chronicPainSyndromeYearOfDiagnosis,
        conditionMatched,
        'chronicPainSyndromeYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  chronicPainSyndromeSpecify: {
    friendlyName: 'Please specify',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.required],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.chronicPainSyndrome.value;

      basicToggleFn(
        formGroup.controls.chronicPainSyndromeSpecify,
        conditionMatched,
        'chronicPainSyndromeSpecify'
      );

      return conditionMatched;
    }
  },
  hepatitis: {
    friendlyName: 'Hepatitis C',
    inputType: {
      type: 'checkbox'
    }
  },
  hepatitisYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.hepatitis.value;

      basicToggleFn(
        formGroup.controls.hepatitisYearOfDiagnosis,
        conditionMatched,
        'hepatitisYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  hivOrAids: {
    friendlyName: 'HIV/AIDS or immunosuppressive disease',
    inputType: {
      type: 'checkbox'
    }
  },
  hivOrAidsYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.hivOrAids.value;

      basicToggleFn(
        formGroup.controls.hivOrAidsYearOfDiagnosis,
        conditionMatched,
        'hivOrAidsYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  htlv: {
    friendlyName: 'Human T-cell lymphotropic virus-1 (HTLV-1)',
    inputType: {
      type: 'checkbox'
    }
  },
  htlvYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.htlv.value;

      basicToggleFn(
        formGroup.controls.htlvYearOfDiagnosis,
        conditionMatched,
        'htlvYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  bellsPalsy: {
    friendlyName: 'Bell’s palsy',
    inputType: {
      type: 'checkbox'
    }
    // subFieldIdList: [
    //   {
    //     id: 'bellsPalsySide',
    //     required: true
    //   },
    //   {
    //     id: 'yearOfDiagnosis',
    //     required: true
    //   }
    // ]
  },
  bellsPalsyYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.bellsPalsy.value;

      basicToggleFn(
        formGroup.controls.bellsPalsyYearOfDiagnosis,
        conditionMatched,
        'bellsPalsyYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  bellsPalsySide: {
    friendlyName: 'Which side is affected?',
    cssClass: {
      questionContainer: 'radio-sub-question-container ',
      questionCss: 'radio-sub-question-label',
      inputTypeCss: 'sub-question-input-type'
    },
    inputType: {
      type: 'radio',
      buttonMap: 'bellsPalsySide'
    },
    validators: [CustomValidator.required],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.bellsPalsy.value;

      basicToggleFn(formGroup.controls.bellsPalsySide, conditionMatched, 'bellsPalsySide');

      return conditionMatched;
    }
  },
  pacemaker: {
    friendlyName: 'Pacemaker or implantable cardioverter defibrillator (ICD)',
    inputType: {
      type: 'checkbox'
    }
  },
  pacemakerYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.pacemaker.value;

      basicToggleFn(
        formGroup.controls.pacemakerYearOfDiagnosis,
        conditionMatched,
        'pacemakerYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  stroke: {
    friendlyName: 'Stroke',
    inputType: {
      type: 'checkbox'
    }
  },
  strokeYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.stroke.value;

      basicToggleFn(
        formGroup.controls.strokeYearOfDiagnosis,
        conditionMatched,
        'strokeYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  cancer: {
    friendlyName: 'Cancer',
    inputType: {
      type: 'checkbox'
    }
  },
  cancerYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.cancer.value;

      basicToggleFn(
        formGroup.controls.cancerYearOfDiagnosis,
        conditionMatched,
        'cancerYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  vitaminDeficiency: {
    friendlyName: 'Vitamin deficiency',
    inputType: {
      type: 'checkbox'
    }
  },
  vitaminDeficiencyYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.vitaminDeficiency.value;

      basicToggleFn(
        formGroup.controls.vitaminDeficiencyYearOfDiagnosis,
        conditionMatched,
        'vitaminDeficiencyYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  vitaminDeficiencySpecify: {
    friendlyName: 'Please specify',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.required],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.vitaminDeficiency.value;

      basicToggleFn(
        formGroup.controls.vitaminDeficiencySpecify,
        conditionMatched,
        'vitaminDeficiencySpecify'
      );

      return conditionMatched;
    }
  },
  sexSteroidDeficiency: {
    friendlyName: 'Sex steroid deficiency',
    inputType: {
      type: 'checkbox'
    }
  },
  sexSteroidDeficiencyYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.sexSteroidDeficiency.value;

      basicToggleFn(
        formGroup.controls.sexSteroidDeficiencyYearOfDiagnosis,
        conditionMatched,
        'sexSteroidDeficiencyYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  sexSteroidDeficiencyDescribe: {
    friendlyName: 'Please describe',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.required],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.sexSteroidDeficiency.value;

      basicToggleFn(
        formGroup.controls.sexSteroidDeficiencyDescribe,
        conditionMatched,
        'sexSteroidDeficiencyDescribe'
      );

      return conditionMatched;
    }
  },
  gout: {
    friendlyName: 'Gout',
    inputType: {
      type: 'checkbox'
    }
  },
  goutYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.gout.value;

      basicToggleFn(
        formGroup.controls.goutYearOfDiagnosis,
        conditionMatched,
        'goutYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  vitiligo: {
    friendlyName: 'Vitiligo',
    inputType: {
      type: 'checkbox'
    }
  },
  vitiligoYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.vitiligo.value;

      basicToggleFn(
        formGroup.controls.vitiligoYearOfDiagnosis,
        conditionMatched,
        'vitiligoYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  liverDisease: {
    friendlyName: 'Liver disease',
    inputType: {
      type: 'checkbox'
    }
  },
  liverDiseaseYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.liverDisease.value;

      basicToggleFn(
        formGroup.controls.liverDiseaseYearOfDiagnosis,
        conditionMatched,
        'liverDiseaseYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  renalFailure: {
    friendlyName: 'Renal failure',
    inputType: {
      type: 'checkbox'
    }
  },
  renalFailureYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.renalFailure.value;

      basicToggleFn(
        formGroup.controls.renalFailureYearOfDiagnosis,
        conditionMatched,
        'renalFailureYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  sjorgenSyndrome: {
    friendlyName: 'Sjögren syndrome',
    inputType: {
      type: 'checkbox'
    }
  },
  sjorgenSyndromeYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.sjorgenSyndrome.value;

      basicToggleFn(
        formGroup.controls.sjorgenSyndromeYearOfDiagnosis,
        conditionMatched,
        'sjorgenSyndromeYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  radiotherapy: {
    friendlyName: 'Radiotherapy',
    inputType: {
      type: 'checkbox'
    }
  },
  radiotherapyYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.radiotherapy.value;

      basicToggleFn(
        formGroup.controls.radiotherapyYearOfDiagnosis,
        conditionMatched,
        'radiotherapyYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  boneMarrow: {
    friendlyName: 'Bone marrow transplantation',
    inputType: {
      type: 'checkbox'
    }
  },
  boneMarrowYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.boneMarrow.value;

      basicToggleFn(
        formGroup.controls.boneMarrowYearOfDiagnosis,
        conditionMatched,
        'boneMarrowYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  irritableBowelSyndrome: {
    friendlyName: 'Irritable bowel syndrome (IBS)',
    inputType: {
      type: 'checkbox'
    }
  },
  irritableBowelSyndromeYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.irritableBowelSyndrome.value;

      basicToggleFn(
        formGroup.controls.irritableBowelSyndromeYearOfDiagnosis,
        conditionMatched,
        'irritableBowelSyndromeYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  polycysticOvary: {
    friendlyName: 'Polycystic ovary syndrome',
    inputType: {
      type: 'checkbox'
    }
  },
  polycysticOvaryYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.polycysticOvary.value;

      basicToggleFn(
        formGroup.controls.polycysticOvaryYearOfDiagnosis,
        conditionMatched,
        'polycysticOvaryYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  fertility: {
    friendlyName: 'Fertility problems',
    inputType: {
      type: 'checkbox'
    }
  },
  fertilityYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.fertility.value;

      basicToggleFn(
        formGroup.controls.fertilityYearOfDiagnosis,
        conditionMatched,
        'fertilityYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  pelvicPain: {
    friendlyName: 'Pelvic pain',
    inputType: {
      type: 'checkbox'
    }
  },
  pelvicPainYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.pelvicPain.value;

      basicToggleFn(
        formGroup.controls.pelvicPainYearOfDiagnosis,
        conditionMatched,
        'pelvicPainYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  psychiatricConditions: {
    friendlyName: 'Psychiatric conditions (e.g. depression, bipolar, schizophrenia)',
    inputType: {
      type: 'checkbox'
    }
  },
  psychiatricConditionsYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.psychiatricConditions.value;

      basicToggleFn(
        formGroup.controls.psychiatricConditionsYearOfDiagnosis,
        conditionMatched,
        'psychiatricConditionsYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  psychiatricConditionsDescribe: {
    friendlyName: 'Please describe',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.required],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.psychiatricConditions.value;

      basicToggleFn(
        formGroup.controls.psychiatricConditionsDescribe,
        conditionMatched,
        'psychiatricConditionsDescribe'
      );

      return conditionMatched;
    }
  },
  demodexSkinInfection: {
    friendlyName: 'Demodex skin infection',
    inputType: {
      type: 'checkbox'
    }
  },
  demodexSkinInfectionYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.demodexSkinInfection.value;

      basicToggleFn(
        formGroup.controls.demodexSkinInfectionYearOfDiagnosis,
        conditionMatched,
        'demodexSkinInfectionYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  androgenDeficiency: {
    friendlyName: 'Androgen deficiency',
    inputType: {
      type: 'checkbox'
    }
  },
  androgenDeficiencyYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.androgenDeficiency.value;

      basicToggleFn(
        formGroup.controls.androgenDeficiencyYearOfDiagnosis,
        conditionMatched,
        'androgenDeficiencyYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  sarcoidosis: {
    friendlyName: 'Sarcoidosis',
    inputType: {
      type: 'checkbox'
    }
  },
  sarcoidosisYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.sarcoidosis.value;

      basicToggleFn(
        formGroup.controls.sarcoidosisYearOfDiagnosis,
        conditionMatched,
        'sarcoidosisYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  accutaneTreatment: {
    friendlyName: 'Treatment with accutane',
    inputType: {
      type: 'checkbox'
    }
  },
  accutaneTreatmentYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.accutaneTreatment.value;

      basicToggleFn(
        formGroup.controls.accutaneTreatmentYearOfDiagnosis,
        conditionMatched,
        'accutaneTreatmentYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  heartDisease: {
    friendlyName: 'Heart disease(s)',
    inputType: {
      type: 'checkbox'
    }
  },
  heartDiseaseYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.heartDisease.value;

      basicToggleFn(
        formGroup.controls.heartDiseaseYearOfDiagnosis,
        conditionMatched,
        'heartDiseaseYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  heartDiseaseSpecify: {
    friendlyName: 'Please specify',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.required],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.heartDisease.value;

      basicToggleFn(
        formGroup.controls.heartDiseaseSpecify,
        conditionMatched,
        'heartDiseaseSpecify'
      );

      return conditionMatched;
    }
  },
  dandruff: {
    friendlyName: 'Dandruff',
    inputType: {
      type: 'checkbox'
    }
  },
  dandruffYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.dandruff.value;

      basicToggleFn(
        formGroup.controls.dandruffYearOfDiagnosis,
        conditionMatched,
        'dandruffYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  scleroderma: {
    friendlyName: 'Scleroderma',
    inputType: {
      type: 'checkbox'
    }
  },
  sclerodermaYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.scleroderma.value;

      basicToggleFn(
        formGroup.controls.sclerodermaYearOfDiagnosis,
        conditionMatched,
        'sclerodermaYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  seborrheicDermatitis: {
    friendlyName: 'Seborrheic dermatitis',
    inputType: {
      type: 'checkbox'
    }
  },
  seborrheicDermatitisYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.seborrheicDermatitis.value;

      basicToggleFn(
        formGroup.controls.seborrheicDermatitisYearOfDiagnosis,
        conditionMatched,
        'seborrheicDermatitisYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  epilepsy: {
    friendlyName: 'Epilepsy',
    inputType: {
      type: 'checkbox'
    }
  },
  epilepsyYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.epilepsy.value;

      basicToggleFn(
        formGroup.controls.epilepsyYearOfDiagnosis,
        conditionMatched,
        'epilepsyYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  rheumaticFever: {
    friendlyName: 'Rheumatic fever',
    inputType: {
      type: 'checkbox'
    }
  },
  rheumaticFeverYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.rheumaticFever.value;

      basicToggleFn(
        formGroup.controls.rheumaticFeverYearOfDiagnosis,
        conditionMatched,
        'rheumaticFeverYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },

  coldSoresOrGentialHerpesOrShingles: {
    friendlyName: 'Cold sores, genital herpes, and/or shingles',
    inputType: {
      type: 'checkbox'
    }
  },
  coldSoresOrGentialHerpesOrShinglesYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.coldSoresOrGentialHerpesOrShingles.value;

      basicToggleFn(
        formGroup.controls.coldSoresOrGentialHerpesOrShinglesYearOfDiagnosis,
        conditionMatched,
        'coldSoresOrGentialHerpesOrShinglesYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  coronaryArteryDisease: {
    friendlyName: 'Coronary artery disease',
    inputType: {
      type: 'checkbox'
    }
  },
  coronaryArteryDiseaseYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.coronaryArteryDisease.value;

      basicToggleFn(
        formGroup.controls.coronaryArteryDiseaseYearOfDiagnosis,
        conditionMatched,
        'coronaryArteryDiseaseYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  lungDisease: {
    friendlyName: 'Lung disease',
    inputType: {
      type: 'checkbox'
    }
  },
  lungDiseaseYearOfDiagnosis: {
    friendlyName: 'Year of diagnosis',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.number()],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.lungDisease.value;

      basicToggleFn(
        formGroup.controls.lungDiseaseYearOfDiagnosis,
        conditionMatched,
        'lungDiseaseYearOfDiagnosis'
      );

      return conditionMatched;
    }
  },
  conditionOther: {
    friendlyName: 'Other conditions',
    inputType: {
      type: 'checkbox'
    }
  },
  conditionOtherDescribe: {
    friendlyName: 'Please describe',
    inputType: {
      type: 'input'
    },
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    validators: [CustomValidator.required],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.conditionOther.value;

      basicToggleFn(
        formGroup.controls.conditionOtherDescribe,
        conditionMatched,
        'conditionOtherDescribe'
      );

      return conditionMatched;
    }
  }
};

export const ocularHealthQuestion: QuestionType = {
  wearsGlasses: {
    friendlyName: 'I wear glasses',
    inputType: { type: 'checkbox' }
  },
  pastContactLensDiscomfort: {
    friendlyName: 'I have contact lens discomfort or intolerance',
    inputType: { type: 'checkbox' }
  },
  lasikOrPRK: {
    friendlyName: 'I had Lasik or PRK',
    inputType: { type: 'checkbox' }
  },
  orthokeratology: {
    friendlyName: 'I had orthokeratology',
    inputType: { type: 'checkbox' }
  },
  myopia: {
    friendlyName:
      'I have myopia (near-sightedness), or I need prescription lenses to see distant objects',
    inputType: { type: 'checkbox' }
  },
  //*************************** */
  hyperopia: {
    friendlyName:
      'I have hyperopia (far-sightedness), or I see distant objects more clearly than nearby objects',
    inputType: { type: 'checkbox' }
  },
  presbyopia: {
    friendlyName: 'I have presbyopia, or I cannot see the computer screen without reading glasses',
    inputType: { type: 'checkbox' }
  },
  amblyopia: {
    friendlyName: 'I have amblyopia, or lazy eye',
    inputType: { type: 'checkbox' }
  },
  diplopia: {
    friendlyName: 'I have diplopia, or double vision',
    inputType: { type: 'checkbox' }
  },
  strabismus: {
    friendlyName: 'I have or had strabismus, or my eyes are not aligned',
    inputType: { type: 'checkbox' }
  },
  eyeTrauma: {
    friendlyName: 'I had trauma or injury to the eyes',
    inputType: { type: 'checkbox' }
  },
  pterygium: {
    friendlyName: "I have pterygium, or surfer's Eye",
    inputType: { type: 'checkbox' }
  },
  nystagmus: {
    friendlyName: 'Nystagmus',
    inputType: { type: 'checkbox' }
  },
  retinalConditions: {
    friendlyName: 'Retinal conditions',
    inputType: { type: 'checkbox' }
  },
  dryEyes: {
    friendlyName: 'Dry eyes (burning, gritty, tearing, etc.)',
    inputType: { type: 'checkbox' }
  },
  prisim: {
    friendlyName: 'Prisim',
    inputType: { type: 'checkbox' }
  },
  keratoconus: {
    friendlyName: 'Keratoconus',
    inputType: { type: 'checkbox' }
  },
  previousEyeInfections: {
    friendlyName: 'Previous eye infections',
    inputType: { type: 'checkbox' }
  },
  cataracts: {
    friendlyName: 'Cataracts',
    inputType: { type: 'checkbox' }
  },
  otherPastOcularHistory: {
    friendlyName: 'Other',
    inputType: { type: 'checkbox' }
  },
  otherPastOcularHistorySpecify: {
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    friendlyName: 'Please Specify',
    inputType: { type: 'input' },
    validators: [CustomValidator.required],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.otherPastOcularHistory.value;

      basicToggleFn(
        formGroup.controls.otherPastOcularHistorySpecify,
        conditionMatched,
        'otherPastOcularHistorySpecify'
      );

      return conditionMatched;
    }
  }
  // doubleVision: {
  //   friendlyName: 'Double Vision (Diplopia)',
  //   inputType: {
  //     type: 'checkbox'
  //   }
  // },
  // previsousEyeInfections: {
  //   friendlyName: 'Previous Eye infections',
  //   inputType: {
  //     type: 'checkbox'
  //   }
  // },
  // lazyEye: {
  //   friendlyName: 'Lazy eye (Amblyopia)',
  //   inputType: {
  //     type: 'checkbox'
  //   }
  // },

  // keratoconus: {
  //   friendlyName: 'Keratoconus',
  //   inputType: {
  //     type: 'checkbox'
  //   }
  // },
  // dryEye: {
  //   friendlyName: 'Dry Eyes (burning, gritty, tearing etc.)',
  //   inputType: {
  //     type: 'checkbox'
  //   }
  // },
  // glaucoma: {
  //   friendlyName: 'Glaucoma',
  //   inputType: {
  //     type: 'checkbox'
  //   }
  // },

  // cataracts: {
  //   friendlyName: 'Cataracts',
  //   inputType: {
  //     type: 'checkbox'
  //   }
  // },
  // nystagmus: {
  //   friendlyName: 'Nystagmus',
  //   inputType: {
  //     type: 'checkbox'
  //   }
  // },
  // retinal: {
  //   friendlyName: `Retinal conditions e.g. retinal
  //   Previous eye infections
  //   `,
  //   inputType: {
  //     type: 'checkbox'
  //   }
  // },
  // eyeCancer: {
  //   friendlyName: 'Cancer of the eye',
  //   inputType: {
  //     type: 'checkbox'
  //   }
  // },
  // prism: {
  //   friendlyName: 'Prisim',
  //   inputType: {
  //     type: 'checkbox'
  //   }
  // },
  // previousEyeSurgery: {
  //   friendlyName: 'Previous Eye surgery',
  //   inputType: {
  //     type: 'checkbox'
  //   }
  // },
  // previousEyeSurgerySpecify: {
  //   cssClass: {
  //     questionContainer: 'sub-question-container',
  //     questionCss: 'sub-question',
  //     inputTypeCss: 'sub-question-input-type'
  //   },
  //   friendlyName: 'Please Specify',
  //   inputType: { type: 'input' },
  //   validators: [CustomValidator.required],
  //   toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
  //     const conditionMatched = formGroup.controls.previousEyeSurgery.value;

  //     basicToggleFn(
  //       formGroup.controls.previousEyeSurgerySpecify,
  //       conditionMatched,
  //       'previousEyeSurgerySpecify'
  //     );

  //     return conditionMatched;
  //   }
  // },
  // other: {
  //   friendlyName: 'Others',
  //   inputType: { type: 'checkbox' }
  // },
  // otherSpecify: {
  //   cssClass: {
  //     questionContainer: 'sub-question-container',
  //     questionCss: 'sub-question',
  //     inputTypeCss: 'sub-question-input-type'
  //   },
  //   friendlyName: 'Please Specify',
  //   inputType: { type: 'input' },
  //   validators: [CustomValidator.required],
  //   toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
  //     const conditionMatched = formGroup.controls.other.value;

  //     basicToggleFn(formGroup.controls.otherSpecify, conditionMatched, 'otherSpecify');

  //     return conditionMatched;
  //   }
  // }
};

export const generalHealthQuestion: QuestionType = {
  aids: {
    friendlyName: `AIDS`,
    inputType: {
      type: 'checkbox'
    }
  },
  keloids: {
    friendlyName: 'Keloids',
    inputType: {
      type: 'checkbox'
    }
  },
  hepatitis: {
    friendlyName: 'Hepatitis',
    inputType: {
      type: 'checkbox'
    }
  },
  epilepsy: {
    friendlyName: 'Epilepsy',
    inputType: {
      type: 'checkbox'
    }
  },
  heartCondition: {
    friendlyName: 'Heart disease/condition',
    inputType: {
      type: 'checkbox'
    }
  },
  hyo_hyper: {
    friendlyName: 'Thyroid (Hyperthyroidism, Hypothyroidism)',
    inputType: {
      type: 'checkbox'
    }
  },
  sores: {
    friendlyName: 'Cold sores, Genital Herpes, and/or shingles',
    inputType: {
      type: 'checkbox'
    }
  },
  smoker: {
    friendlyName: 'Smoker',
    inputType: {
      type: 'checkbox'
    }
  },
  respiratory: {
    friendlyName: `Asthma/Lung Disease
    Autoimmune Disease: (Rheumatoid
    Arthritis, Lupus, MS, Crohn's, Sarcoidosis,
    Myasthenia Gravis, Vasculitis, etc.)
    `,
    inputType: {
      type: 'checkbox'
    }
  },
  pregnancy: {
    friendlyName: 'Pregnant and/or breastfeeding',
    inputType: {
      type: 'checkbox'
    }
  },
  rheumatic: {
    friendlyName: 'Rheumatic Fever',
    inputType: {
      type: 'checkbox'
    }
  },
  asthma: {
    friendlyName: 'Asthma/Lung Disease',
    inputType: {
      type: 'checkbox'
    }
  },

  liver: {
    friendlyName: 'Liver Disease/Jaundice',
    inputType: {
      type: 'checkbox'
    }
  },
  blood: {
    friendlyName: 'High Blood Pressure',
    inputType: {
      type: 'checkbox'
    }
  },
  artery: {
    friendlyName: 'Coronary Artery Disease',
    inputType: {
      type: 'checkbox'
    }
  },
  diabetes: {
    friendlyName: 'Diabetes',
    inputType: { type: 'checkbox' }
  },
  diabetesType: {
    friendlyName: 'Type',
    cssClass: {
      questionContainer: 'radio-sub-question-container ',
      questionCss: 'radio-sub-question-label',
      inputTypeCss: 'sub-question-input-type'
    },
    inputType: {
      type: 'radio',
      buttonMap: 'types'
    },
    validators: [CustomValidator.required],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.diabetes.value;

      basicToggleFn(formGroup.controls.diabetesType, conditionMatched, 'diabetesType');

      return conditionMatched;
    }
  },
  insulin: {
    friendlyName: 'Insulin',
    cssClass: {
      questionContainer: 'radio-sub-question-container ',
      questionCss: 'radio-sub-question-label',
      inputTypeCss: 'sub-question-input-type'
    },
    inputType: {
      type: 'radio',
      buttonMap: 'generalRadioButton'
    },
    validators: [CustomValidator.required],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.diabetes.value;

      basicToggleFn(formGroup.controls.insulin, conditionMatched, 'diabetesType');

      return conditionMatched;
    }
  },
  thyroid: {
    friendlyName: 'Thyroid Disease',
    inputType: {
      type: 'checkbox'
    }
  },
  seizure: {
    friendlyName: 'Seizure Disorder',
    inputType: {
      type: 'checkbox'
    }
  },
  cancer: {
    friendlyName: 'Cancer/ leukemia',
    inputType: {
      type: 'checkbox'
    }
  }
};

export const generalButtonMap = {
  contactLensTypeRadioButton: {
    hard: 'Hard',
    soft: 'Soft'
  },
  thirdGeneralRadioButton: {
    no: 'No, I never have',
    past: 'No, but I have in the past',
    yes: 'Yes'
  },
  secondGeneralRadioButton: {
    yes: 'Yes',
    no: 'No',
    dontKnow: `I Don't Know`
  },
  generalRadioButton: {
    yes: 'Yes',
    no: 'No'
  },
  types: {
    1: '1',
    2: '2'
  },
  bellsPalsySide: {
    left: 'Left',
    right: 'Right'
  },
  age: {
    under18: 'Under 18',
    _18To39: '18 - 39',
    _40To64: '40 - 64',
    _64OrOlder: '64 or Older'
  },
  referrals: {
    optometrist: 'Optometrist',
    famDoctor: 'Family Doctor',
    existingPatient: 'I am an existing patient',
    other: 'Other'
  },
  statementsDescribingRefractive: {
    forLifeStyleReasons: 'For life style reasons.',
    betterWithoutGlasses: 'I think I look better without glasses and do not like contact lenses',
    careerReason: 'For career reasons.',
    reduceDependency: 'To reduce my dependence on glasses and/or contact lenses.'
  },
  conditions: {
    diabetes: 'Diabetes',
    autoimmuneDisease: `Autoimmune Disease (i.e. AIDS,
      Lupus, Rheumatoid Arthritis, Multiple
      Sclerosis, Myasthenia Gravis, etc.)`,
    immunocompromised: 'Immunocompromised for any reason',
    collagenVascular: 'Collagen Vascular Disease',
    noConditions: 'To the best of my knowledge I have none of these conditions'
  },
  nearsightedness: {
    myopia: 'Nearsightedness (Myopia)',
    hyperopia: 'Farsightedness (Hyperopia)',
    dontKnow: `I Don't Know`
  },
  myOpia: {
    low: 'Low (Less than -3D)',
    moderate: 'Moderate (-3D to -6D)',
    high: 'High (Greater than -6D up to -9D)',
    veryHight: 'Very High (Greater than -9D)',
    dontKnow: `I Don't Know`
  }
};

export const patientScreening: QuestionType = {
  referredBy: {
    friendlyName: `How were you referred ?`,
    inputType: {
      type: 'radio',
      buttonMap: 'referrals'
    },
    validators: [CustomValidator.required]
  },
  optometristName: {
    friendlyName: 'Optometrist Name',
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    inputType: { type: 'input' },
    validators: [CustomValidator.required],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.referredBy.value === 'optometrist';

      basicToggleFn(formGroup.controls.optometristName, conditionMatched, 'optometristName');

      return conditionMatched;
    }
  },
  familyDoctorName: {
    friendlyName: `Family Doctor's Name`,
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    inputType: { type: 'input' },
    validators: [CustomValidator.required],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.referredBy.value === 'famDoctor';

      basicToggleFn(formGroup.controls.familyDoctorName, conditionMatched, 'familyDoctorName');

      return conditionMatched;
    }
  },
  otherName: {
    friendlyName: 'Please Specify',
    cssClass: {
      questionContainer: 'sub-question-container',
      questionCss: 'sub-question',
      inputTypeCss: 'sub-question-input-type'
    },
    inputType: { type: 'input' },
    validators: [CustomValidator.required],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.referredBy.value === 'other';

      basicToggleFn(formGroup.controls.otherName, conditionMatched, 'otherName');

      return conditionMatched;
    }
  },
  lookingForRefractiveSurgery: {
    friendlyName: 'Are you looking for refractive surgery?',
    inputType: {
      type: 'radio',
      buttonMap: 'generalRadioButton'
    },
    validators: [CustomValidator.required]
  },
  describeRefractiveSurgery: {
    friendlyName:
      'Which of the following statements best reflects your primary reason for seeking Refractive surgery?',
    inputType: {
      type: 'radio',
      buttonMap: 'statementsDescribingRefractive'
    },
    validators: [CustomValidator.required],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.lookingForRefractiveSurgery.value === 'yes';

      basicToggleFn(
        formGroup.controls.describeRefractiveSurgery,
        conditionMatched,
        'describeRefractiveSurgery'
      );

      return conditionMatched;
    }
  },
  condition: {
    friendlyName: '',
    questionTitle: 'Do you have any of the following conditions? Please select all that apply.',
    inputType: {
      type: 'checkboxGroup',
      checkboxes: {
        diabetes: 'Diabetes',
        autoimmuneDisease: `Autoimmune Disease (i.e. AIDS,
        Lupus, Rheumatoid Arthritis, Multiple
        Sclerosis, Myasthenia Gravis, etc.)`,
        immunocompromised: 'Immunocompromised for any reason',
        collagenVascular: 'Collagen Vascular Disease'
      }
    },
    validators: [CustomValidator.atLeastOneCheckBoxRequired],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const disableCondition = formGroup.controls.noCondition.value;
      const lookingForRefractive = formGroup.controls.lookingForRefractiveSurgery.value === 'yes';

      const toggleFn = (disable: boolean) => {
        Object.values((formGroup.controls.condition as FormGroup).controls).forEach(
          (control: FormControl) => {
            if (disable) {
              control.setValue(null, { onlySelf: true });
              control.disable({ onlySelf: true });
            } else {
              control.enable({ onlySelf: true });
              control.markAsTouched({ onlySelf: true });
            }
          }
        );

        if (!lookingForRefractive) {
          setTimeout(() => {
            formGroup.controls.condition.disable({ onlySelf: true });
          });
        }
      };

      setTimeout(() => {
        toggleFn(!lookingForRefractive || disableCondition);
      });

      return lookingForRefractive || disableCondition;
    }
  },

  noCondition: {
    friendlyName: 'To the best of my knowledge I have none of these conditions',
    questionTitle: '',
    inputType: {
      type: 'checkbox'
    },
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.lookingForRefractiveSurgery.value === 'yes';

      basicToggleFn(formGroup.controls.noCondition, conditionMatched, 'noCondition');

      return conditionMatched;
    }
  },

  slowHealing: {
    friendlyName:
      'Are you currently on any medications such as steroids, immuno-suppressants, which can slow or prevent healing?',
    inputType: {
      type: 'radio',
      buttonMap: 'generalRadioButton'
    },
    validators: [CustomValidator.required],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.lookingForRefractiveSurgery.value === 'yes';

      basicToggleFn(formGroup.controls.slowHealing, conditionMatched, 'slowHealing');

      return conditionMatched;
    }
  },
  refractiveError: {
    friendlyName: 'What type of refractive error do you have?',
    inputType: {
      type: 'radio',
      buttonMap: 'nearsightedness'
    },
    validators: [CustomValidator.required],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.lookingForRefractiveSurgery.value === 'yes';

      basicToggleFn(formGroup.controls.refractiveError, conditionMatched, 'refractiveError');

      return conditionMatched;
    }
  },
  levelOfNearsightedness: {
    friendlyName: 'What level of nearsightedness (myopia) do you have?',
    inputType: {
      type: 'radio',
      buttonMap: 'myOpia'
    },
    validators: [CustomValidator.required],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.lookingForRefractiveSurgery.value === 'yes';

      basicToggleFn(
        formGroup.controls.levelOfNearsightedness,
        conditionMatched,
        'levelOfNearsightedness'
      );

      return conditionMatched;
    }
  },
  astigmatism: {
    friendlyName: 'Do you have astigmatism?',
    inputType: {
      type: 'radio',
      buttonMap: 'secondGeneralRadioButton'
    },
    validators: [CustomValidator.required],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.lookingForRefractiveSurgery.value === 'yes';

      basicToggleFn(formGroup.controls.astigmatism, conditionMatched, 'astigmatism');

      return conditionMatched;
    }
  },
  prescription: {
    friendlyName: 'Has your prescription changed over the past two years?',
    inputType: {
      type: 'radio',
      buttonMap: 'generalRadioButton'
    },
    validators: [CustomValidator.required],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.lookingForRefractiveSurgery.value === 'yes';

      basicToggleFn(formGroup.controls.prescription, conditionMatched, 'prescription');

      return conditionMatched;
    }
  },
  nightDriving: {
    friendlyName:
      'Are you experiencing vision problems when driving at night, such as halos, glare or blurriness?',
    inputType: {
      type: 'radio',
      buttonMap: 'generalRadioButton'
    },
    validators: [CustomValidator.required],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.lookingForRefractiveSurgery.value === 'yes';

      basicToggleFn(formGroup.controls.nightDriving, conditionMatched, 'nightDriving');

      return conditionMatched;
    }
  },
  willingnessToSurgery: {
    friendlyName: `Like all surgical procedures, Refractive surgery has the risk of complications. Are you
    willing to educate yourself about those risks, accept a reasonable risk if you are an
    appropriate candidate and comply with a schedule of post-surgery medications and follow-
    up exams?`,
    inputType: {
      type: 'radio',
      buttonMap: 'generalRadioButton'
    },
    validators: [CustomValidator.required],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.lookingForRefractiveSurgery.value === 'yes';

      basicToggleFn(
        formGroup.controls.willingnessToSurgery,
        conditionMatched,
        'willingnessToSurgery'
      );

      return conditionMatched;
    }
  },
  enforcement: {
    friendlyName: `Are you considering applying to police, RCMP, Military?`,
    inputType: {
      type: 'radio',
      buttonMap: 'generalRadioButton'
    },
    validators: [CustomValidator.required],
    toggleCondition: ({ formGroup }: { formGroup: FormGroup }) => {
      const conditionMatched = formGroup.controls.lookingForRefractiveSurgery.value === 'yes';

      basicToggleFn(formGroup.controls.enforcement, conditionMatched, 'enforcement');

      return conditionMatched;
    }
  }
};

function basicToggleFn(formControl: AbstractControl, conditionMatched: boolean, key) {
  if (!formControl) {
    console.log('misiing: ' + key);
  }
  if (conditionMatched) {
    setTimeout(() => {
      formControl.enable({ onlySelf: true });
      formControl.markAsTouched({ onlySelf: true });
    });
    return conditionMatched;
  }

  setTimeout(() => {
    formControl.markAsUntouched({ onlySelf: true });
    formControl.disable({ onlySelf: true });

    if (formControl.value) {
      formControl.setValue(null, { onlySelf: true });
    }
  });
}

interface QuestionType {
  [key: string]: QuestionConfig;
}

interface QuestionConfig {
  friendlyName?: string;
  questionTitle?: string;
  subQuestions?: QuestionType;
  cssClass?: CssClassName;
  compareWith?: MapCompare;
  mapTo?: boolean;
  buttonMap?: string;
  inputType?: InputTypeConfig;
  validators?: ValidatorFn[];
  toggleCondition?: ({ formGroup }: { formGroup: FormGroup }) => boolean;
}

interface CssClassName {
  questionContainer: string;
  questionCss: string;
  inputTypeCss: string;
}

type InputTypeConfig =
  | RadioButton
  | Checkbox
  | Input
  | TextBox
  | Date
  | CheckboxGroup
  | Medication
  | Slider;

interface Slider {
  type: 'slider';
  min: number;
  max: number;
  step: number;
  bottomLabels?: string[];
}
interface RadioButton {
  type: 'radio';
  buttonMap: string;
}

interface Checkbox {
  type: 'checkbox';
  buttonMap?: string;
  checkBoxGroup?: { [key: string]: string };
}

interface CheckboxGroup {
  type: 'checkboxGroup';
  checkboxes: {
    [key: string]: string;
  };
}

interface Input {
  type: 'input';
}
interface Medication {
  type: 'medication';
}

interface Date {
  type: 'date';
}
interface TextBox {
  type: 'textbox';
}

interface MapCompare {
  [key: string]: { controlKey: string; compareKey: string };
}

interface None {
  type: 'none';
}

export enum IntakeFormGroupType {
  PatientCard = 'patient-card',
  Mixed = 'mixed',
  CheckboxGroup = 'checkbox-group'
}

export interface IntakeFormSchema {
  [key: string]: {
    friendlyName: string;
    config: QuestionType;
    type: IntakeFormGroupType;
    depracatedResponseGroupKey?: string;
    columnType?: 'single' | 'double' | 'triple';
  };
  // Inserted questionnaires are not shown here. They must be hard-coded
}

export const intakeFormSchema: IntakeFormSchema = {
  patientInformation: {
    friendlyName: 'Demographics Information',
    config: {},
    type: IntakeFormGroupType.PatientCard
  },
  patientGeneralHealthQuestion: {
    friendlyName: 'General Information',
    config: patientGeneralHealthQuestion,
    type: IntakeFormGroupType.Mixed,
    columnType: 'triple',
    depracatedResponseGroupKey: 'patientGeneralHeathResponse'
  },
  ocularHealthQuestion: {
    friendlyName: 'Ocular History',
    config: ocularHealthQuestion,
    type: IntakeFormGroupType.CheckboxGroup,
    depracatedResponseGroupKey: 'ocularResponse'
  },
  eyeSurgeryQuestion: {
    friendlyName: 'Past Eye Surgeries',
    config: eyeSurgeryQuestion,
    type: IntakeFormGroupType.CheckboxGroup,
    depracatedResponseGroupKey: 'pastEyeSurgeries'
  },
  medicalConditions: {
    friendlyName: 'Medical Conditions',
    config: medicalConditions,
    type: IntakeFormGroupType.CheckboxGroup
  },
  patientScreening: {
    friendlyName: 'Pre-screening',
    config: patientScreening,
    type: IntakeFormGroupType.Mixed,
    depracatedResponseGroupKey: 'patientScreeningResponse'
  }
};
