import { OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ClinicSetup } from 'src/API';
import { CreateClinicSetupInput, UpdateClinicSetupInput } from './../../../API';
import { AbstractControlsMap } from './../../shared/dynamic-form-group/dynamic-form-group';
import { WelcomeMessageConfigFormGroup } from './customizable-welcome-notification/customizable-welcome-notification.component';
import { Layout } from './layouts/layouts.service';

interface ClinicSetupFormControls extends AbstractControlsMap {
  id: FormControl;
  signsLayout: FormControl;
  textFavorites: FormControl;
  treatmentTemplates: FormControl;
  medicalHistoryLayout: FormControl;
  layouts: FormControl;
  defaultQuestionnaireLanguage: FormControl;
  defaultPdfLanguage: FormControl;
  temperatureUnit: FormControl;
  questionnaireExpiration: FormControl;
  welcomeMessageConfig: WelcomeMessageConfigFormGroup;
}

export class ClinicSetupFormGroup extends FormGroup implements OnInit {
  private readonly DEFAULT_DAYS = 14;

  controls: ClinicSetupFormControls;

  constructor() {
    super({
      id: new FormControl(),
      textFavorites: new FormControl(),
      treatmentTemplates: new FormControl(),
      layouts: new FormControl(),
      defaultQuestionnaireLanguage: new FormControl(),
      defaultPdfLanguage: new FormControl(),
      temperatureUnit: new FormControl(),
      questionnaireExpiry: new FormControl(),
      welcomeMessageConfig: new WelcomeMessageConfigFormGroup()
    });
  }

  ngOnInit() {}

  patchValueFromClinicSetup(clinicSetup: ClinicSetup) {
    if (clinicSetup) {
      this.patchValue({
        ...clinicSetup,
        welcomeMessageConfig: JSON.parse(clinicSetup.welcomeMessageConfig || '{}')
      });
    }
  }

  updateClinicSetupInput(): UpdateClinicSetupInput {
    const {
      id,
      layouts,
      defaultPdfLanguage,
      defaultQuestionnaireLanguage,
      temperatureUnit,
      questionnaireExpiry,
      welcomeMessageConfig
    } = this.value;
    const updateInput = {
      id,
      defaultPdfLanguage,
      defaultQuestionnaireLanguage,
      temperatureUnit,
      questionnaireExpiry: questionnaireExpiry || this.DEFAULT_DAYS,
      welcomeMessageConfig: JSON.stringify(welcomeMessageConfig)
    };

    if (this.controls.layouts.dirty) {
      updateInput['layouts'] = (layouts as Layout[]).map(layout => ({
        ...layout,
        symptomSettings: JSON.stringify(layout.symptomSettings)
      }));
    }
    return updateInput;
  }

  getCreateClinicSetupInput(clinicSetupClinicId: string): CreateClinicSetupInput {
    const createClinicSetupInput: CreateClinicSetupInput = { clinicSetupClinicId };

    return createClinicSetupInput;
  }
}
